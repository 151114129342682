import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RecordDetail from "../../models/RecordDetail";
import {
  addAnswerToRecord,
  setRecordStatus,
} from "../../service/RecordsDetailsService";

type Props = {
  children: JSX.Element;
  record: RecordDetail;
};

export default function SuggestDontAnswerModal({ children, record }: Props) {
  const [show, setShow] = useState(false);
  const [author, setAuthor] = useState("");
  const [note, setNote] = useState("");
  const [timeSpent, setTimeSpent] = useState("");
  let navigate = useNavigate();

  const handleDontAnswer = () => {
    // add answer with internal flag set to 1, on success, change the status
    addAnswerToRecord(
      record.type,
      record.message_id,
      author,
      timeSpent,
      "Interní zpráva - navrženo k ponechání bez odpovědi",
      note,
      "1"
    ).then(
      () => {
        setRecordStatus(
          record.type,
          record.message_id,
          "neodpovidat-navrh"
        ).then(() => {
          toast.success("Navrženo k neodpovídání!");
          navigate("/sluzba");
        });
      },
      () => {
        toast.error("Nepodařilo se navrhnout zprávu k neodpovídání");
      }
    );
  };

  const handleAuthor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthor(event.target.value);
  };

  const handleTimeSpent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeSpent(event.target.value);
  };

  const handleNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const user = localStorage.getItem("userName");
    if (user) {
      setAuthor(user);
    } else {
      setAuthor("");
    }
  }, []);

  return (
    <>
      <div onClick={handleShow}>{children}</div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Navrhnout k označení "neodpovídat"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form.Label htmlFor="answerAuthor">Uživatel: </Form.Label>
            <Form.Control
              type="text"
              value={author}
              id="answerAuthor"
              onChange={handleAuthor}
            />

            <Form.Label htmlFor="timeSpent">Čas odpovědi: </Form.Label>
            <Form.Control
              type="number"
              value={timeSpent}
              id="timeSpent"
              onChange={handleTimeSpent}
            />

            <Form.Label htmlFor="answerNote">Poznámka: </Form.Label>
            <Form.Control
              type="text"
              value={note}
              id="answerNote"
              onChange={handleNote}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleDontAnswer}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
