import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sluzba from "./scenes/Sluzba";
import CoursesExport from "./scenes/CoursesExport";
import { store } from "./store";
import { Provider } from "react-redux";
import SluzbaEmail from "./scenes/SluzbaEmail";
import SluzbaTrustbox from "./scenes/SluzbaTrustbox";
import SluzbaAlik from "./scenes/SluzbaAlik";
import EmailDetail from "./scenes/EmailDetail";
import PageNotFound from "./components/PageNotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithAuth from "./components/auth/WithAuth";
import WithOnFocusLoginCheck from "./components/WithOnFocusLoginCheck";
import TrustboxDetail from "./scenes/TrustboxDetail";
import Users from "./scenes/Users";
import Settings from "./scenes/Settings";
import UserSettings from "./scenes/UserSettings";
import AlikDetail from "./scenes/AlikDetail";
import AlikAdmin from "./scenes/AlikAdmin";
import AlikStats from "./scenes/AlikStats";
import SluzbaReports from "./scenes/SluzbaReports";
import ReportDetail from "./scenes/ReportDetail";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <ToastContainer autoClose={3000} hideProgressBar={true} />
    <Provider store={store}>
      <WithOnFocusLoginCheck>
        <WithAuth>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/coursesExport" element={<CoursesExport />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/sluzba" element={<Sluzba />} />
              <Route path="/sluzba/alik" element={<SluzbaAlik />} />
              <Route path="/sluzba/alik/:recordId" element={<AlikDetail />} />
              <Route path="/sluzba/alikAdmin" element={<AlikAdmin />} />
              <Route path="/sluzba/alikStats" element={<AlikStats />} />
              <Route path="/sluzba/email" element={<SluzbaEmail />} />
              <Route path="/sluzba/email/:recordId" element={<EmailDetail />} />
              <Route path="/sluzba/trustbox" element={<SluzbaTrustbox />} />
              <Route
                path="/sluzba/trustbox/:recordId"
                element={<TrustboxDetail />}
              />
              <Route path="/sluzba/zapisy/:page" element={<SluzbaReports />} />
              <Route
                path="/sluzba/zapis/:reportId"
                element={<ReportDetail />}
              />
              <Route path="/user-settings" element={<UserSettings />} />
              <Route path="/users" element={<Users />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </WithAuth>
      </WithOnFocusLoginCheck>
    </Provider>
  </>
);
