import { axiosInstance } from "./SetupAxios";

export async function loginUser(username, password) {
  let payload = { username: username, password: password };

  const { data: response } = await axiosInstance
    .post("login.php", payload)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function checkLoginState() {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const { data: response } = await axiosInstance
      .get("user/loginCheck.php", config)
      .catch((error) => {
        console.log(error);
      });

    return response;
  }
}

export async function refreshLogin() {
  const refreshToken = localStorage.getItem("refreshToken");

  // console.log("Refreshing access token");

  if (refreshToken) {
    if (validateJwtToken(refreshToken)) {
      let payload = { refreshtoken: refreshToken };

      const { data: response } = await axiosInstance
        .post("login.php", payload)
        .catch((error) => {
          console.log(error);
        });
      localStorage.setItem("accessToken", response.token);
      return response.token;
    }
  }
  return false;
}

export async function logoutUser() {
  const token = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userName");
  localStorage.removeItem("userRole");
  localStorage.removeItem("userUserId");

  let payload = { refreshToken: refreshToken };

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .post("logout.php", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export function parseJwtToken(token) {
  if (token) {
    return JSON.parse(atob(token.split(".")[1]));
  } else {
    return 0;
  }
}

export function validateJwtToken(token) {
  if (token !== "") {
    const parsedData = JSON.parse(atob(token.split(".")[1]));
    return parsedData.exp > Date.now() / 1000;
  } else {
    return false;
  }
}
