import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { getAlikStats } from "../service/AlikAdminService";
import { toast } from "react-toastify";

function AlikAdmin() {
  const [fromDate, setFromDate] = useState(() => {
    return new Date().toISOString().slice(0, 10);
  });
  const [toDate, setToDate] = useState(() => {
    return new Date().toISOString().slice(0, 10);
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableEditDate, setDisableEditDate] = useState(true);
  const [dateRange, setDateRange] = useState("normal");
  const [alikStats, setAlikStats] = useState([]);
  const [authorStats, setAuthorStats] = useState([]);

  const handleChangeDateRange = (e) => {
    e.persist();
    setDateRange(e.target.value);
    var date = new Date();
    switch (e.target.value) {
      case "thisMonth":
        setFromDate(
          new Date(date.getFullYear(), date.getMonth(), 2)
            .toISOString()
            .slice(0, 10)
        );
        setToDate(date.toISOString().slice(0, 10));
        setDisableSubmit(false);
        setDisableEditDate(true);
        break;
      case "lastMonth":
        setFromDate(
          new Date(date.getFullYear(), date.getMonth() - 1, 2)
            .toISOString()
            .slice(0, 10)
        );
        setToDate(
          new Date(new Date(date.getFullYear(), date.getMonth(), 1) - 1)
            .toISOString()
            .slice(0, 10)
        );
        setDisableSubmit(false);
        setDisableEditDate(true);
        break;
      default:
        setDisableEditDate(false);
        setDisableSubmit(false);
        break;
    }
  };

  const handleSubmit = async (e) => {
    const data = await getAlikStats(fromDate, toDate);

    var alikArray = [];

    // find individial aliks
    data.forEach((element) => {
      if (element?.id != null) {
        // if alik array does not already contain item with this id
        if (!alikArray.some((item) => item.id === element.id)) {
          alikArray.push({
            id: element?.id,
            nazev_alika: element?.nazev_alika,
            created_on: element?.created_on,
            odevzdano_on: element?.odevzdano_on,
            alik_entered_on: element?.alik_entered_on,
            responses: [],
            consulatations: [],
          });
        }
      }
    });

    // find responses and consultations for aliks
    alikArray.forEach((alik) => {
      data.forEach((element) => {
        if (element?.id != null && element.id === alik.id) {
          // if response array does not already contain item with this id
          if (
            !alik.responses.some((item) => item.answerId === element.answerId)
          ) {
            alik.responses.push({
              answerId: element?.answerId,
              answerAuthor: element?.answerAuthor,
              answerTimeSpent: element?.answerTimeSpent,
            });
          }
          // if consultation array does not already contain item with this id
          if (
            !alik.consulatations.some(
              (item) => item.consultationId === element.consultationId
            )
          ) {
            alik.consulatations.push({
              consultationId: element?.consultationId,
              consultationAuthor: element?.consultationAuthor,
              consultationTimeSpent: element?.consultationTimeSpent,
            });
          }
        }
      });
    });

    var globalAuthors = [];

    alikArray.forEach((alik) => {
      // deduplicate answer authors
      var answerAuthors = [];
      alik.responses.forEach((response) => {
        if (!answerAuthors.includes(response.answerAuthor)) {
          answerAuthors.push(response.answerAuthor);
        }
        // save global author or increase answer count
        if (
          !globalAuthors.some((author) => author.name === response.answerAuthor)
        ) {
          globalAuthors.push({
            name: response.answerAuthor,
            answerCount: 1,
            consultationCount: 0,
            timeSpent: parseInt(response.answerTimeSpent),
          });
        } else {
          globalAuthors.forEach((author) => {
            if (author.name === response.answerAuthor) {
              author.answerCount += 1;
              author.timeSpent += parseInt(response.answerTimeSpent);
            }
          });
        }
      });
      alik.answerAuthors = answerAuthors;

      // deduplicate consultation authors
      var consultationAuthors = [];
      alik.consulatations.forEach((consultation) => {
        if (!consultationAuthors.includes(consultation.consultationAuthor)) {
          consultationAuthors.push(consultation.consultationAuthor);
        }
        // save global author or increase consultation count
        if (
          !globalAuthors.some(
            (author) => author.name === consultation.consultationAuthor
          )
        ) {
          globalAuthors.push({
            name: consultation.consultationAuthor,
            answerCount: 0,
            consultationCount: 1,
            timeSpent: parseInt(consultation.consultationTimeSpent),
          });
        } else {
          globalAuthors.forEach((author) => {
            if (author.name === consultation.consultationAuthor) {
              author.consultationCount += 1;
              author.timeSpent += parseInt(consultation.consultationTimeSpent);
            }
          });
        }
      });
      alik.consultationAuthors = consultationAuthors;

      // sum up answer times
      var ansSum = 0;
      alik.responses.forEach((response) => {
        ansSum += parseInt(response.answerTimeSpent);
      });
      alik.answerTimeSpent = ansSum;

      // sum up consultation times
      var consSum = 0;
      alik.consulatations.forEach((consultation) => {
        consSum += parseInt(consultation.consultationTimeSpent);
      });
      alik.consultationTimeSpent = consSum;
    });

    setAlikStats(alikArray);
    setAuthorStats(globalAuthors);

    toast.success("Statistiky načteny");
  };

  const countHoursAndRoundToHalfHourUp = (minutes) =>
    Math.ceil((minutes / 60) * 2) / 2;

  useEffect(() => {}, []);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container className="AlikStatsTimerange">
        <Card>
          <Card.Header>
            <h3>Období</h3>
          </Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="timeRange">
                    <b>Rozsah</b>
                    <Form.Check
                      value="thisMonth"
                      type="radio"
                      aria-label="radioTimeRange"
                      label="Tento měsíc"
                      onChange={handleChangeDateRange}
                      checked={dateRange === "thisMonth"}
                    />
                    <Form.Check
                      value="lastMonth"
                      type="radio"
                      aria-label="radioTimeRange"
                      label="Poslední měsíc"
                      onChange={handleChangeDateRange}
                      checked={dateRange === "lastMonth"}
                    />
                    <Form.Check
                      value="custom"
                      type="radio"
                      aria-label="radioTimeRange"
                      label="Vlastní"
                      onChange={handleChangeDateRange}
                      checked={dateRange === "custom"}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Label htmlFor="timeRangeFrom">
                    <b>Od</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="timeRangeFrom"
                    value={fromDate}
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    disabled={disableEditDate}
                  />
                </Col>

                <Col>
                  <Form.Label htmlFor="timeRangeTo">
                    <b>Do</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="timeRangeTo"
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                    disabled={disableEditDate}
                  />
                </Col>
              </Row>

              <br></br>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={disableSubmit}
              >
                Načíst data
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Header>
            <h3>Alík</h3>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Číslo a předmět</th>
                  <th>Alík vložen na web alik.cz</th>
                  <th>Vloženo do systému Modré linky</th>
                  <th>Odesláno</th>
                  <th>Autor odpovědi</th>
                  <th>Autor konzultace</th>
                  <th>Souhrnný čas odpovědí</th>
                  <th>Souhrnný čas konzultací</th>
                </tr>
              </thead>
              <tbody>
                {alikStats.map((alik) => {
                  return (
                    <tr>
                      <td>{alik.nazev_alika}</td>
                      <td>{alik.alik_entered_on}</td>
                      <td>{alik.created_on}</td>
                      <td>{alik.odevzdano_on}</td>
                      <td>
                        {alik.answerAuthors.map((author) => {
                          return <div key={author}>{author}</div>;
                        })}
                      </td>
                      <td>
                        {alik.consultationAuthors.map((author) => {
                          return <div key={author}>{author}</div>;
                        })}
                      </td>
                      <td>
                        {countHoursAndRoundToHalfHourUp(alik.answerTimeSpent)} h
                        ({alik.answerTimeSpent} minut)
                      </td>
                      <td>
                        {countHoursAndRoundToHalfHourUp(
                          alik.consultationTimeSpent
                        )}{" "}
                        h ({alik.consultationTimeSpent} minut)
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <br />

        <Card>
          <Card.Header>
            <h3>Autoři</h3>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Jméno</th>
                  <th>Počet odpovědí</th>
                  <th>Počet konzultací</th>
                  <th>Celkem odpracováno</th>
                </tr>
              </thead>
              <tbody>
                {authorStats.map((author) => {
                  return (
                    <tr>
                      <td>{author.name}</td>
                      <td>{author.answerCount}</td>
                      <td>{author.consultationCount}</td>
                      <td>
                        {countHoursAndRoundToHalfHourUp(author.timeSpent)} h (
                        {author.timeSpent} minut)
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default AlikAdmin;
