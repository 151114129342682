import RecordDetail, { RecordType } from "../models/RecordDetail";
import { Dropdown } from "react-bootstrap";
import ForwardToModal from "./modals/ForwardToModal";
import {
  forwardEmail,
  forwardEmailAndSoftDelete,
} from "../service/RecordsService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getStatus } from "./statusAndTypeTools";
import SuggestDontAnswerModal from "./modals/SuggestDontAnswerModal";
import { assignToUser } from "../service/AssignmentService";

interface MoreActionsMenuProps {
  record: RecordDetail;
}

const MoreActionsMenu = (props: MoreActionsMenuProps) => {
  const navigate = useNavigate();

  const handleForward = (target: string, targetName: string) => {
    if (
      window.confirm(
        "Opravdu chcete email přeposlat jako " +
          targetName +
          " a skrýt ze systému?"
      )
    ) {
      forwardEmailAndSoftDelete(props.record.message_id, target).then(() => {
        toast.success("Přeposláno!");
        navigate("/sluzba");
      });
    }
  };

  const handleNotifyEnTeam = (target: string) => {
    if (window.confirm("Opravdu chcete email předat AJ týmu?")) {
      // send notification
      forwardEmail(props.record.message_id, target).then(() => {
        // assign to aj team
        assignToUser(props.record.type, "Aj tym", props.record.message_id).then(
          () => {
            toast.success("Předáno AJ týmu!");
            navigate("/sluzba");
          }
        );
      });
    }
  };

  return (
    <Dropdown style={{ display: "inline" }}>
      <Dropdown.Toggle variant={"secondary"} id="dropdown-moreActions">
        Další akce
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/*Add validation before submitting*/}
        {props.record.status !== getStatus("neodpovidat-navrh") ? (
          <SuggestDontAnswerModal record={props.record}>
            <Dropdown.Item>Ponechat bez odpovědi</Dropdown.Item>
          </SuggestDontAnswerModal>
        ) : null}
        {props.record.type === RecordType.email ? (
          <>
            <Dropdown.Item>
              <ForwardToModal id={props.record.message_id}>
                Přeposlat na email
              </ForwardToModal>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleNotifyEnTeam(
                  "linka-english-mails-aaaadv7ihlqpkhoefzhi2ql6dy@modralinka.slack.com"
                );
              }}
            >
              Předat AJ týmu
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleForward(
                  "nabidka-vzdelavani-aaaaiasi7u63diwwcnvr45vkli@modralinka.slack.com",
                  "nabídka vzdělávání"
                );
              }}
            >
              Označit jako nabídka vzdělávání
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleForward(
                  "preposlano-ze-systemu-aaaaiash3uzsda6ki3k5uxm2fe@modralinka.slack.com",
                  "spam"
                );
              }}
            >
              Označit jako spam (či ke smazání)
            </Dropdown.Item>
          </>
        ) : null}
        {props.record.status === getStatus("neodpovidat-navrh") &&
        props.record.type !== RecordType.email ? (
          <Dropdown.Item>
            V tomto stavu nejsou možné žádné další akce
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreActionsMenu;
