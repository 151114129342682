import { toast } from "react-toastify";
import { downloadNewEmailRecords } from "../service/RecordsService";
import "../css/fetchMailButtonStyle.css";

export default function FetchMailButton(props) {
  const fetchNewMail = () => {
    toast.info("Zahajuji stahování nové pošty, prosím vyčkejte");
    downloadNewEmailRecords().then(() => {
      toast.success("Nová pošta byla stažena!", { autoClose: 5000 });
      props.refreshParent();
    });
  };

  return (
    <img
      src="\img\Command-Reset.png"
      className="FetchMailButton"
      title="Načíst novou poštu"
      alt="Načíst novou poštu"
      onClick={fetchNewMail}
    ></img>
  );
}
