import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jwt: "",
  loggedIn: false,
  userId: 0,
  username: "",
  firstName: "",
  surname: "",
  role: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setJwt: (state, action) => {
      state.jwt = action.payload;
    },
    clearJwt: (state) => {
      state.jwt = "";
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    clearUserId: (state) => {
      state.userId = "";
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    clearUsername: (state) => {
      state.username = "";
    },
    setFirstname: (state, action) => {
      state.firstName = action.payload;
    },
    clearFirstname: (state) => {
      state.firstName = "";
    },
    setSurname: (state, action) => {
      state.surname = action.payload;
    },
    clearSurname: (state) => {
      state.surname = "";
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    clearRole: (state) => {
      state.role = "";
    },
  },
});

export const {
  setJwt,
  clearJwt,
  setLoggedIn,
  setUserId,
  setUsername,
  clearUsername,
  setFirstname,
  clearFirstName,
  setSurname,
  clearSurname,
  setRole,
  clearRole,
} = userSlice.actions;

export default userSlice.reducer;
