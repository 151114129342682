import { useState } from "react";
import ReactPaginate from "react-paginate";
import "../css/paginationStyle.css";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { insertColor } from "./statusAndTypeTools";
import { RecordType } from "../models/RecordDetail";

const insertLinkHref = (type, id) => {
  if (type === RecordType.email) {
    return "/sluzba/email/" + id;
  } else if (type === RecordType.trustbox) {
    return "/sluzba/trustbox/" + id;
  } else if (type === RecordType.alik) {
    return "/sluzba/alik/" + id;
  }
};

function MlPaginatedTable(props) {
  const [page, setPage] = useState(0);
  const recordsPerPage = 20;
  const totalPages = Math.ceil(props.records.length / recordsPerPage);

  const colorByImportance = (importance) => {
    switch (importance) {
      case "important":
        return { backgroundColor: "orange" };
      case "urgent":
        return { backgroundColor: "red" };
      default:
        return { backgroundColor: "unset" };
    }
  };

  const displayRecords = props.records
    .slice(page * recordsPerPage, page * recordsPerPage + recordsPerPage)
    .map((record) => {
      return (
        <tr
          key={record.type + record.id}
          style={colorByImportance(record.important)}
        >
          <td>
            {props.showColor ? insertColor(record.type) : null}
            {new Date(record.date).toLocaleString("cs-CZ")}
          </td>
          <td className="statusTd">{record.status}</td>
          <td>{record.title.slice(0, 70)}</td>
          <td>{record.assignedTo}</td>
          <td>
            <Link
              to={insertLinkHref(record.type, record.id)}
              className="recordLink"
            >
              <Button variant={props.buttonVariant}>Zvolit</Button>
            </Link>
          </td>
        </tr>
      );
    });

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  return (
    <>
      <Table className="recordsTable" striped bordered id="recordsTable">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Status</th>
            <th>Název</th>
            <th>Přiřazeno</th>
            <th>Vybrat</th>
          </tr>
        </thead>
        <tbody>{displayRecords}</tbody>
      </Table>
      <ReactPaginate
        pageCount={totalPages}
        previousLabel={"Předchozí"}
        nextLabel={"Další"}
        onPageChange={changePage}
        containerClassName={"navigationButtons"}
        previousLinkClassName={"previousButton"}
        nextLinkClassName={"nextButton"}
        disabledClassName={"navigationDisabled"}
        activeClassName={"navigationActive"}
      />
    </>
  );
}

export default MlPaginatedTable;
export { insertLinkHref };
