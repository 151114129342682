import { Link } from "react-router-dom";
import "../css/pageNotFoundStyle.css";

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <div className="glitch">Něco se nepovedlo :-(</div>
      <div className="backHome">
        Stránka kterou se snažíte zobrazit neexistuje
      </div>
      <Link to="/" className="backHome">
        Zpět domů
      </Link>
    </div>
  );
};

export default PageNotFound;
