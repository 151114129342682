import "../App.css";
import { useEffect } from "react";
import { checkLoginState } from "../service/LoginService";

// User has switched back to the tab
const onFocus = () => {
  checkLoginState();
};

// User has switched away from the tab (AKA tab is hidden)
const onBlur = () => {};

export default function WithAuth(props) {
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return <>{props.children}</>;
}
