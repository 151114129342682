import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getReport } from "../service/ReportService";
import { formatIsoDateToCZLocale } from "../utils";
import { ReportAnswer, ReportDetailT } from "../models/Report";
import { RecordType } from "../models/RecordDetail";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

const reportElementId = "reportElement";

function ReportDetail() {
  const { reportId } = useParams();
  const [report, setReport] = useState<ReportDetailT | null>(null);
  const [renderedReport, setRenderedReport] = useState<JSX.Element>(<></>);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);

  function copyToClipboard() {
    var copyText = document.getElementById(reportElementId);

    if (copyText) {
      navigator.clipboard.writeText(copyText.innerText);
      toast.success("Zápis byl zkopírován do schránky!");
    }
  }

  useEffect(() => {
    getReport(reportId?.split("-")[0], reportId?.split("-")[1]).then(
      (response) => {
        if (response) {
          setReport(response);
        }
      }
    );
  }, [reportId]);

  const renderAnswer = (answer: ReportAnswer) => {
    return (
      <div style={{ paddingLeft: "1em" }} key={answer.id}>
        <div>
          Text odpovědi:{" "}
          <span dangerouslySetInnerHTML={{ __html: answer.text }}></span>
        </div>
        <div>
          {answer.author}; {answer.timeSpent} minut; {answer.note}
        </div>
        {!answer.consultations ??
          answer.consultations.map((consultation) => {
            return (
              <div
                style={{ paddingLeft: "1em" }}
                key={consultation.author + consultation.text.substring(0, 3)}
              >
                <div>
                  {consultation.author}; {consultation.timeSpent} minut{" "}
                  <div
                    dangerouslySetInnerHTML={{ __html: consultation.text }}
                  ></div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  useEffect(() => {
    if (report) {
      setRenderedReport(
        <>
          <div>Autor odpovědi: {report.finalAnswerAuthor}</div>
          <div>
            {report.type === RecordType.email ? "Název E-Mailu: " : ""}
            {report.type === RecordType.trustbox ? "Název SIPu: " : ""}
            {report.type === RecordType.alik ? "Číslo a předmět: " : ""}
            {report.subject}
          </div>
          <div>
            Datum a čas odeslání: {formatIsoDateToCZLocale(report.dateSent)};
            Autor odeslané odpovědi: {report.finalAnswerAuthor}; Souhrnný čas
            všech odpovědí a konzultací: {totalTimeSpent}
          </div>
          <br />

          <div>
            Zpráva:{" "}
            <div dangerouslySetInnerHTML={{ __html: report.message }}></div>
          </div>
          <br />

          {report.answers.map((answer) => {
            if (answer.useThisAnswer === "1") {
              return <>Odeslaná odpověď: {renderAnswer(answer)}</>;
            } else {
              return <></>;
            }
          })}

          {report.answers.map((answer) => {
            if (answer.useThisAnswer !== "1") {
              return (
                <>
                  <br />
                  {renderAnswer(answer)}
                </>
              );
            } else {
              return <></>;
            }
          })}
        </>
      );

      let time = 0;
      if (report?.answers) {
        report.answers.forEach((answer) => {
          time += parseInt(answer.timeSpent);
          if (answer?.consultations) {
            answer.consultations.forEach((consultation) => {
              time += parseInt(consultation.timeSpent);
            });
          }
        });
      }
      setTotalTimeSpent(time);
    } else {
      setRenderedReport(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }
  }, [report, totalTimeSpent]);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container>
        <br />
        <Card>
          <Card.Header>
            <Button onClick={copyToClipboard} variant={"secondary"}>
              Zkopírovat zápis
            </Button>
          </Card.Header>
          <Card.Body id={reportElementId}>{renderedReport}</Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default ReportDetail;
