import { axiosInstance } from "./SetupAxios";
import { toast } from "react-toastify";

export async function getAgregatedRecords() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("records/all.php", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function getEmailsRecords() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("records/emails.php", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function getAlikRecords() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("records/alik.php", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function getTrustboxRecords() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("records/trustbox.php", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function downloadNewEmailRecords() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 30000,
  };

  const response = await axiosInstance
    .get("email/fetchNew.php", config)
    .catch((error) => {
      console.log(error);
      toast.error("Nová pošta se nepodařila stáhnout!");
    });
  if (response) {
    return response?.data;
  }
}

export async function getEmailForwardTargets() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 20000,
  };

  const { data: response } = await axiosInstance
    .get("records/forward.php?action=getTargets", config)
    .catch((error) => {
      console.log(error);
      toast.error("Seznam adres pro přeposlání se nepodařilo stáhnout!");
    });
  return response;
}

export async function forwardEmail(messageId, forwardTo) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 20000,
  };

  let payload = { id: messageId, to: forwardTo };

  const { data: response } = await axiosInstance
    .post("records/forward.php?action=forward&delete=false", payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Předání se nepodařilo!");
    });
  return response;
}

export async function forwardEmailAndSoftDelete(messageId, forwardTo) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 20000,
  };

  let payload = { id: messageId, to: forwardTo };

  const { data: response } = await axiosInstance
    .post("records/forward.php?action=forward", payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Přeposlání se nepodařilo!");
    });
  return response;
}

export async function setEmailImportance(messageId, newValue) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { id: messageId, important: newValue };

  const { data: response } = await axiosInstance
    .post("records/emails.php?action=setImportant", payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Změna důležitosti se nepovedla!");
    });
  return response;
}

export async function setTrustboxImportance(messageId, newValue) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { id: messageId, important: newValue };

  const { data: response } = await axiosInstance
    .post("records/trustbox.php?action=setImportant", payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Změna důležitosti se nepovedla!");
    });
  return response;
}

export async function setAlikImportance(messageId, newValue) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { id: messageId, important: newValue };

  const { data: response } = await axiosInstance
    .post("records/alik.php?action=setImportant", payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Změna důležitosti se nepovedla!");
    });
  return response;
}
