import { axiosInstance } from "./SetupAxios";
import { toast } from "react-toastify";

export async function postNewAlik(
  subject,
  author,
  authorAge,
  enteredDate,
  importance,
  text,
  assignedTo,
  alikNote = ""
) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  if (assignedTo === "empty") {
    assignedTo = null;
  }

  const payload = {
    subject: subject,
    author: author,
    authorAge: authorAge,
    enteredDate: enteredDate,
    importance: importance,
    text: text,
    assignedTo: assignedTo,
    alikNote: alikNote,
  };

  var url = "records/alikAdmin.php?action=addNew";

  const { data: response } = await axiosInstance
    .post(url, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se vložit Alíka");
    });
  return response;
}

export async function getAlikStats(dateFrom, dateTo) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  var url =
    "stats/alikStats.php?action=alikStats&dateFrom=" +
    dateFrom +
    "&dateTo=" +
    dateTo;

  toast.info("Stahuji statistiky pro Alíky", { autoClose: 2000 });

  const { data: response } = await axiosInstance
    .get(url, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se stáhnout statistiky pro Alíky");
    });
  return response;
}
