import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { useCallback, useEffect, useState } from "react";
import {
  getCoursesDetails,
  getCoursesInstances,
  getPublishedCourses,
} from "../service/CoursesService";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { ArrowLeftCircleFill } from "react-bootstrap-icons";
import { exportAllCertificates } from "../components/certificateExport/ExportCertificate";
import { RecordType } from "../models/RecordDetail";

function CoursesExport() {
  const [courses, setCourses] = useState([]);
  const [courseNumber, setCourseNumber] = useState(0);
  const [courseInstances, setCourseInstances] = useState([]);
  const [instanceNumber, setInstanceNumber] = useState(0);
  const [instanceCapacity, setInstanceCapacity] = useState(0);
  const [instanceDetails, setInstanceDetails] = useState([]);

  let dataHeader = <></>;
  let tableBody = <></>;

  const goBack = () => {
    if (instanceNumber !== 0) {
      setInstanceNumber(0);
    } else {
      setCourseNumber(0);
    }
  };

  const createPDF = useCallback((table_id) => {
    const sTable = document.getElementById(table_id);

    let style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style =
      style +
      "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";

    // CREATE A WINDOW OBJECT.
    const win = window.open("", "", "height=700,width=700");

    win.document.write("<html><head>");
    win.document.write("<title>Kurz</title>"); // <title> FOR PDF HEADER.
    win.document.write(style); // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write("</head>");
    win.document.write("<body>");

    win.document.write("<table>" + sTable.innerHTML + "</table>");

    win.document.write("</body></html>");

    win.document.close(); // CLOSE THE CURRENT WINDOW.

    win.print(); // PRINT THE CONTENTS.
  }, []);

  const createCsv = useCallback((table_id, separator = ",") => {
    // Select rows from table_id
    const rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    const csv = [];
    for (let i = 0; i < rows.length; i++) {
      const row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cols.length; j++) {
        // Clean inner text to remove multiple spaces and jumpline (break csv)
        let data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    const csv_string = csv.join("\n");
    // Download it
    const filename =
      "export_" + table_id + "_" + new Date().toDateString() + ".csv";
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  useEffect(() => {
    if (instanceNumber !== 0) {
      getCoursesDetails(instanceNumber).then((response) => {
        if (response) {
          setInstanceDetails(response);
        }
      });
    } else if (courseNumber !== 0) {
      getCoursesInstances(courseNumber).then((response) => {
        if (response) {
          setCourseInstances(response);
        }
      });
    } else {
      getPublishedCourses().then((response) => {
        if (response) {
          setCourses(response);
        }
      });
    }
  }, [setCourses, courseNumber, instanceNumber]);

  if (instanceNumber !== 0) {
    dataHeader = (
      <>
        <th>Přihlášen</th>
        <th>Pořadí</th>
        <th>Jméno</th>
        <th>Příjmení</th>
        <th>Datum narození</th>
        <th>Telefon</th>
        <th>Email</th>
        <th>Adresa</th>
        <th>Místo narození</th>
        <th>Registrace na organizaci</th>
      </>
    );
    tableBody = instanceDetails
      .sort((a, b) => {
        return a["created_on"] < b["created_on"] ? -1 : 1;
      })
      .map((instance, index) => {
        return (
          <tr key={instance["id"] + "i"}>
            <td>{instance["created_on"]}</td>
            <td>
              {index < instanceCapacity
                ? index + 1
                : "Náhradník " + (index + 1 - instanceCapacity)}
            </td>
            <td>{instance["firstname"]}</td>
            <td>{instance["surname"]}</td>
            <td>{instance["date_of_birth"]}</td>
            <td>{instance["phone"]}</td>
            <td>{instance[RecordType.email]}</td>
            <td>
              {(instance["street"] ? instance["street"] + ", " : "") +
                (instance["city"] ? instance["city"] + ", " : "") +
                (instance["postal_code"] ? instance["postal_code"] : "")}
            </td>
            <td>{instance["place_of_birth"]}</td>
            <td>
              {(instance["company"] ? instance["company"] + ", " : "") +
                (instance["companyPhone"]
                  ? instance["companyPhone"] + ", "
                  : "") +
                (instance["companyEmail"] ? instance["companyEmail"] : "")}
            </td>
          </tr>
        );
      });
  } else if (courseNumber !== 0) {
    dataHeader = (
      <>
        <th>Datum konání</th>
        <th width={"100%"}>Místo</th>
        <th>Vybrat</th>
      </>
    );
    tableBody = courseInstances.map((course) => {
      return (
        <tr key={course["id"]}>
          <td>{course["start_date"]}</td>
          <td>{course["venue"]}</td>
          <td>
            <Button
              variant="success"
              onClick={() => {
                setInstanceNumber(course["id"]);
                if (course["capacity"]) {
                  setInstanceCapacity(parseInt(course["capacity"]));
                }
              }}
            >
              Vybrat
            </Button>
          </td>
        </tr>
      );
    });
  } else {
    dataHeader = (
      <>
        <th width={"100%"}>Název kurzu</th>
        <th>Vybrat</th>
      </>
    );
    tableBody = courses.map((course) => {
      return (
        <tr key={course["id"]}>
          <td>
            {course["status"] === "draft" ? <b>Rozpracováno - </b> : null}
            {course["name"]}
          </td>
          <td>
            <Button
              variant="success"
              onClick={() => setCourseNumber(course["id"])}
            >
              Vybrat
            </Button>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container>
        {courseNumber !== 0 ? (
          <Button variant="secondary" onClick={goBack}>
            <ArrowLeftCircleFill></ArrowLeftCircleFill> Zpět
          </Button>
        ) : null}

        {instanceNumber !== 0 ? (
          <>
            <span style={{ margin: "1px" }}></span>
            <Button variant="success" onClick={() => createCsv("coursesTable")}>
              Export do csv
            </Button>
            <span style={{ margin: "1px" }}></span>
            <Button variant="danger" onClick={() => createPDF("coursesTable")}>
              Export do pdf
            </Button>
            <span style={{ margin: "1px" }}></span>
            <Button
              variant="primary"
              onClick={() => {
                exportAllCertificates(
                  courses,
                  courseInstances,
                  courseNumber,
                  instanceNumber,
                  instanceDetails
                );
              }}
            >
              Exportovat osvědčení
            </Button>
          </>
        ) : null}

        <h1>Export kurzů</h1>
        <Table striped bordered hover id="coursesTable">
          <thead>
            <tr>{dataHeader}</tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </Table>
      </Container>
    </>
  );
}

export default CoursesExport;
