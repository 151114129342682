import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { Container, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getSettingsList, setSettingsValue } from "../service/SettingsService";
import { toast } from "react-toastify";

function Settings() {
  var [settings, setSettings] = useState([]);

  useEffect(() => {
    getSettingsList().then((response) => {
      if (response) {
        setSettings(response);
      }
    });
  }, [setSettings]);

  var settingsRows = settings.map((setting) => {
    return (
      <Row key={setting.setting}>
        <Col>{setting.setting}:</Col>
        <Col>
          <input
            defaultValue={setting.value}
            style={{ appearance: "textfield" }}
            onBlur={(e) => {
              if (e.target.value !== setting.value) {
                setSettingsValue(setting.setting, e.target.value).then(() => {
                  toast.success("Nastavení " + setting.setting + " uloženo!");
                });
              }
            }}
          ></input>
        </Col>
      </Row>
    );
  });

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container className="Users">
        <h1>Nastavení aplikace</h1>
        {settingsRows}
      </Container>
    </>
  );
}

export default Settings;
