import { axiosInstance } from "./SetupAxios";
import { toast } from "react-toastify";
import { RecordType } from "../models/RecordDetail";

export async function setMedId(newId, type, recordId) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const payload = {
    messageId: recordId,
    medId: newId,
  };

  var url = "";
  switch (type) {
    case RecordType.email:
      url = "records/medid.php?type=email";
      break;
    case RecordType.trustbox:
      url = "records/medid.php?type=trustbox";
      break;
    case RecordType.alik:
      url = "records/medid.php?type=alik";
      break;
    default:
      throw new Error("Invalid type of record: " + type);
  }

  const { data: response } = await axiosInstance
    .post(url, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se uložit MED ID");
    });
  return response;
}
