import { axiosInstance } from "./SetupAxios";

export async function getUserList() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { data: response } = await axiosInstance
    .get("user/manage.php?action=list", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function createNewUser(login, name, role, password, email) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = {
    login: login,
    name: name,
    role: role,
    password: password,
    email: email,
  };

  const { data: response } = await axiosInstance
    .post("user/manage.php?action=addUser", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function resetUserPassword(login, password) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { login: login, password: password };

  const { data: response } = await axiosInstance
    .post("user/manage.php?action=resetPassword", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function lockUser(login, locked) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { login: login, locked: locked };

  const { data: response } = await axiosInstance
    .post("user/manage.php?action=lockUser", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function deleteUser(login) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { login: login };

  const { data: response } = await axiosInstance
    .post("user/manage.php?action=deleteUser", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}
