import { axiosInstance } from "./SetupAxios";
import { toast } from "react-toastify";

export async function generateReport(type, messageId) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };

  const url =
    "reports/reports.php?action=generate&type=" + type + "&id=" + messageId;

  const { data: response } = await axiosInstance
    .post(url, {}, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se vygenerovat zápis");
    });
  return response;
}

export async function getReport(type, messageId) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };

  const url = "reports/reports.php?action=get&id=" + type + "-" + messageId;

  const { data: response } = await axiosInstance
    .get(url, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se načíst zápis");
    });
  return response;
}

export async function listReports(page, limit) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };

  const url =
    "reports/reports.php?action=list&page=" + page + "&limit=" + limit;

  const { data: response } = await axiosInstance
    .get(url, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se načíst seznam zápisů");
    });
  return response;
}

export async function countReports() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };

  const url = "reports/reports.php?action=count";

  const { data: response } = await axiosInstance
    .get(url, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se načíst počet zápisů");
    });
  return response;
}
