import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAssignableUsers } from "../service/AssignmentService";
import { postNewAlik } from "../service/AlikAdminService";

function AlikAdmin() {
  const [alikText, setAlikText] = useState("");
  const [alikSubject, setAlikSubject] = useState("");
  const [alikName, setAlikName] = useState("");
  const [alikAge, setAlikAge] = useState(0);
  const [disableAlikAge, setDisableAlikAge] = useState(false);
  const [alikEnteredDate, setAlikEnteredDate] = useState(() => {
    var now = new Date();
    return (
      now.getFullYear() +
      "-" +
      ("0" + (now.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + now.getDate()).slice(-2)
    );
  });
  const [alikNote, setAlikNote] = useState("");

  const [alikTextValidated, setAlikTextValidated] = useState(true);
  const [alikSubjectValidated, setAlikSubjectValidated] = useState(true);
  const [alikNameValidated, setAlikNameValidated] = useState(true);
  const [alikAgeValidated, setAlikAgeValidated] = useState(true);
  const [alikEnteredDateValidated, setAlikEnteredDateValidated] =
    useState(true);

  const [disableSubmitToConsultation, setDisableSubmitToConsultation] =
    useState(true);
  const [importance, setImportance] = useState("normal");
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [assignToNotSelected, setAssignToNotSelected] = useState(true);

  const handleAlikText = (e) => {
    setAlikText(e.target.value);
    if (e.target.value.length > 0) {
      setAlikTextValidated(true);
      setDisableSubmitToConsultation(false);
    } else {
      setAlikTextValidated(false);
    }
  };

  const handleAlikSubject = (e) => {
    setAlikSubject(e.target.value);
    if (e.target.value.length > 0) {
      setAlikSubjectValidated(true);
      setDisableSubmitToConsultation(false);
    } else {
      setAlikSubjectValidated(false);
    }
  };

  const handleAlikName = (e) => {
    setAlikName(e.target.value);
    if (e.target.value.length > 0) {
      setAlikNameValidated(true);
      setDisableSubmitToConsultation(false);
    } else {
      setAlikNameValidated(false);
    }
  };

  const handleAlikEnteredDate = (e) => {
    setAlikEnteredDate(e.target.value);
    if (e.target.value) {
      setAlikEnteredDateValidated(true);
      setDisableSubmitToConsultation(false);
    } else {
      setAlikEnteredDateValidated(false);
    }
  };

  const handleAlikAge = (e) => {
    setAlikAge(e.target.value);
    if (e.target.value > 0) {
      setAlikAgeValidated(true);
      setDisableSubmitToConsultation(false);
    } else {
      setAlikAgeValidated(false);
    }
  };

  const handleAlikNote = (e) => {
    setAlikNote(e.target.value);
  };

  const handleChangeImportance = (e) => {
    e.persist();
    setImportance(e.target.value);
  };

  const handleSubmit = (e) => {
    setDisableSubmitToConsultation(true);
    if (
      alikTextValidated &&
      alikSubjectValidated &&
      alikNameValidated &&
      alikAgeValidated &&
      alikEnteredDateValidated
    ) {
      postNewAlik(
        alikSubject,
        alikName,
        alikAge,
        alikEnteredDate,
        importance,
        alikText,
        selectedUserId,
        alikNote
      ).then(() => {
        toast.success("Alík byl vložen!");
        setAlikText("");
        setAlikAge("");
        setAlikName("");
        setAlikNote("");
        setAlikSubject("");
        setImportance("normal");
        setSelectedUserId(0);
        setAssignToNotSelected(true);
        setAlikEnteredDate(() => {
          var now = new Date();
          return (
            now.getFullYear() +
            "-" +
            ("0" + (now.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + now.getDate()).slice(-2)
          );
        });
      });
    } else {
      toast.warning(
        "Některá pole mají neplatnou hodnotu, prosím zkontrolujte formulář!"
      );
      setDisableSubmitToConsultation(false);
    }
  };

  useEffect(() => {
    getAssignableUsers().then((response) => {
      if (response) {
        setUsers(
          response.map((u) => {
            return (
              <option value={u.ID_osoby} key={u.ID_osoby}>
                {u.Jmeno}
              </option>
            );
          })
        );
      }
    });
  }, []);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container className="AlikAdmin">
        <br />
        <Card>
          <Card.Header>
            <h3>Vložit Alíka do systému</h3>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Label htmlFor="alikSubject">
                <b>Číslo a předmět:</b>
              </Form.Label>
              <Form.Control
                type="text"
                value={alikSubject}
                id="alikSubject"
                onChange={handleAlikSubject}
                isInvalid={!alikSubjectValidated}
              />

              <Row>
                <Col>
                  <Form.Label htmlFor="alikName">
                    <b>Jméno pisatele:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={alikName}
                    id="alikName"
                    onChange={handleAlikName}
                    isInvalid={!alikNameValidated}
                  />
                  <Row>
                    <Col>
                      <Form.Label htmlFor="alikAge">
                        <b>Věk pisatele:</b>
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Label htmlFor="alikAgeNone">Neuvedeno</Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="alikAgeNone"
                        onChange={(e) => {
                          setAlikAge(0);
                          if (e.target.checked) {
                            setAlikAgeValidated(true);
                            setDisableAlikAge(true);
                          } else {
                            setDisableAlikAge(false);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Form.Control
                    type="number"
                    value={alikAge}
                    id="alikAge"
                    onChange={handleAlikAge}
                    isInvalid={!alikAgeValidated}
                    disabled={disableAlikAge}
                  />
                  <Form.Label
                    htmlFor="alikEnteredDate"
                    title="Datum vložení záznamu do systému alik.cz"
                  >
                    <b>Datum vložení na alik.cz:</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={alikEnteredDate}
                    id="alikEnteredDate"
                    onChange={handleAlikEnteredDate}
                    isInvalid={!alikEnteredDateValidated}
                  />
                </Col>

                <Col>
                  <Form.Group controlId="kindOfStand">
                    <b>Důležitost</b>
                    <Form.Check
                      value="normal"
                      type="radio"
                      aria-label="radio 1"
                      label="Standardní"
                      onChange={handleChangeImportance}
                      checked={importance === "normal"}
                    />
                    <Form.Check
                      value="important"
                      type="radio"
                      aria-label="radio 1"
                      label="Důležité"
                      onChange={handleChangeImportance}
                      checked={importance === "important"}
                    />
                    <Form.Check
                      value="urgent"
                      type="radio"
                      aria-label="radio 2"
                      label="Urgentní"
                      onChange={handleChangeImportance}
                      checked={importance === "urgent"}
                    />
                  </Form.Group>

                  <Form.Label htmlFor="assignToUser">
                    <b>Přiřadit uživateli:</b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Assign to user selector"
                    id="assignToUser"
                    onChange={(e) => {
                      setSelectedUserId(e.target.value);
                      setAssignToNotSelected(false);
                    }}
                  >
                    <option
                      value="empty"
                      key="empty"
                      selected={assignToNotSelected}
                    >
                      -- Nepřiřazeno --
                    </option>
                    {users}
                  </Form.Select>
                </Col>
              </Row>

              <Form.Label htmlFor="alikText">
                <b>Text Alíka:</b>
              </Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                value={alikText}
                rows={6}
                id="alikText"
                onChange={handleAlikText}
                isInvalid={!alikTextValidated}
              ></Form.Control>

              <br />

              <Form.Label htmlFor="alikNote">
                <b>Poznámka vkládajícího:</b>
              </Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                value={alikNote}
                rows={1}
                id="alikNote"
                onChange={handleAlikNote}
              />

              <br></br>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={disableSubmitToConsultation}
              >
                Vložit do systému
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default AlikAdmin;
