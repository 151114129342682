import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { Card, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ReportListItem } from "../models/Report";
import { countReports, listReports } from "../service/ReportService";
import { Link, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { formatIsoDateToCZLocale } from "../utils";

function SluzbaReports() {
  const { page } = useParams();
  const [reportCount, setReportCount] = useState<number>(0);
  const [reportList, setReportList] = useState<Array<ReportListItem>>([]);

  useEffect(() => {
    countReports().then((response) => {
      if (response) {
        setReportCount(parseInt(response?.count));
      }
    });
  }, []);

  useEffect(() => {
    if (!page) {
      return;
    }
    listReports(parseInt(page), 25).then((response) => {
      if (response) {
        setReportList(response);
        console.log(response);
      }
    });
  }, [page]);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container>
        <br />
        <Card>
          <Card.Header>
            <h2>Zápisy</h2>
          </Card.Header>
          <Table>
            <thead>
              <tr>
                <th>Typ</th>
                <th>Odesláno</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reportList
                .sort((a, b) => {
                  return (
                    new Date(b.created).getTime() -
                    new Date(a.created).getTime()
                  );
                })
                .map((report) => {
                  return (
                    <tr key={report.id}>
                      <td>
                        <b>
                          {report.type === "email" ? "E-Mail" : ""}
                          {report.type === "trustbox" ? "SIP" : ""}
                          {report.type === "alik" ? "Alík" : ""}
                        </b>
                      </td>
                      <td>{formatIsoDateToCZLocale(report.created)}</td>
                      <td>
                        <Link to={"/sluzba/zapis/" + report.id}>Zobrazit</Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card>
        <center>
          {[...Array(Math.ceil(reportCount / 25)).keys()].map((page) => {
            return (
              <Link key={page} to={"/sluzba/zapisy/" + (page + 1)}>
                {page + 1}{" "}
              </Link>
            );
          })}
          <br />
          Strana {page} z {Math.ceil(reportCount / 25)}
        </center>
      </Container>
    </>
  );
}

export default SluzbaReports;
