import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { resetUserPassword } from "../../service/UserService";

export default function ResetPasswordModal(props) {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleResetPassword = () => {
    if (password !== "") {
      if (password.length < 12) {
        toast.warning("Heslo musí mít alespoň 12 znaků");
      } else {
        resetUserPassword(props.login, password).then(() => {
          setShow(false);
          toast.success("Heslo změněno!");
          props.refreshParent();
        });
      }
    } else {
      toast.warning("Uživatel nelze uložit, některé z polí není vyplněné");
    }
  };

  return (
    <>
      <Button
        variant="warning"
        value="Resetovat heslo"
        onClick={handleShow}
        title="Resetovat heslo"
      >
        Resetovat heslo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Resetovat heslo uživatele {props.login}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>Heslo:</Row>
            <Row>
              <Form.Control
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></Form.Control>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleResetPassword}>
            Resetovat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
