import { axiosInstance } from "./SetupAxios";
import { toast } from "react-toastify";

export async function getAssignableUsers() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("records/assignments.php?action=getUsers", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function assignToUser(type, user, record) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { type: type, user: user, record: record };

  const { data: response } = await axiosInstance
    .post("records/assignments.php?action=assign", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export function handleRemoveAssigneeAfterStateChange(
  recordtype,
  recordid,
  message
) {
  if (window.confirm(message + ", chcete zrušit přiřazení?")) {
    assignToUser(recordtype, 0, recordid).then(() => {
      toast.success("Přiřazení zrušeno!");
    });
  }
}
