import { axiosInstance } from "./SetupAxios";

export async function getPublishedCourses() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("courses/index.php", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function getCoursesInstances(courseId) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("courses/index.php?instancesof=" + courseId, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function getCoursesDetails(instanceId) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { data: response } = await axiosInstance
    .get("courses/index.php?detailsof=" + instanceId, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}
