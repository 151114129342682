import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { Container, Col, Row } from "react-bootstrap";
import ResetPasswordModal from "../components/modals/ResetPasswordModal";
import { useEffect, useState } from "react";
import { parseJwtToken } from "../service/LoginService";

function UserSettings() {
  const [login, setLogin] = useState<String>("");
  useEffect(() => {
    setLogin(parseJwtToken(localStorage.getItem("refreshToken")).username);
  }, []);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container>
        <h1>Uživatelské nastavení</h1>
        <Row>
          <Col>
            <h3>Resetovat heslo</h3>
          </Col>
          <Col>
            <ResetPasswordModal login={login}></ResetPasswordModal>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserSettings;
