import { axiosInstance } from "./SetupAxios";
import { toast } from "react-toastify";
import { RecordType } from "../models/RecordDetail";

export async function getEmailDetails(recordID) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };

  const { data: response } = await axiosInstance
    .get("records/emails.php?detailsof=" + recordID, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se stáhnout data");
    });
  return response;
}

export async function addAnswerToRecord(
  type,
  recordID,
  author,
  timeSpent,
  answerText,
  answerNote,
  internal = "0"
) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const payload = {
    messageId: recordID,
    answerAuthor: author,
    timeSpent: timeSpent,
    answerText: answerText,
    answerNote: answerNote,
    internal: internal,
  };

  var url = "";
  switch (type) {
    case RecordType.email:
      url = "records/emails.php?action=saveAnswer";
      break;
    case RecordType.trustbox:
      url = "records/trustbox.php?action=saveAnswer";
      break;
    case RecordType.alik:
      url = "records/alik.php?action=saveAnswer";
      break;
    default:
      throw new Error("Invalid type of record: " + type);
  }

  const { data: response } = await axiosInstance
    .post(url, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se uložit odpověď");
    });
  return response;
}

export async function setRecordStatus(type, recordID, newStatus) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const payload = {
    messageId: recordID,
    newStatus: newStatus,
  };

  var url = "";
  switch (type) {
    case RecordType.email:
      url = "records/emails.php?action=changeStatus";
      break;
    case RecordType.trustbox:
      url = "records/trustbox.php?action=changeStatus";
      break;
    case RecordType.alik:
      url = "records/alik.php?action=changeStatus";
      break;
    default:
      throw new Error("Invalid type of record: " + type);
  }

  const { data: response } = await axiosInstance
    .post(url, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se uložit nový status");
    });
  return response;
}

export async function updateRecordAnswer(
  type,
  answerId,
  author,
  timeSpent,
  answerText,
  answerNote
) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const payload = {
    answerId: answerId,
    answerAuthor: author,
    timeSpent: timeSpent,
    answerText: answerText,
    answerNote: answerNote,
  };

  var url = "";
  switch (type) {
    case RecordType.email:
      url = "records/emails.php?action=updateAnswer";
      break;
    case RecordType.trustbox:
      url = "records/trustbox.php?action=updateAnswer";
      break;
    case RecordType.alik:
      url = "records/alik.php?action=updateAnswer";
      break;
    default:
      throw new Error("Invalid type of record: " + type);
  }

  const { data: response } = await axiosInstance
    .post(url, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se uložit odpověď");
    });
  return response;
}

export async function addConsultationToAnswer(
  type,
  answerId,
  author,
  timeSpent,
  answerText
) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const payload = {
    messageId: answerId,
    answerAuthor: author,
    timeSpent: timeSpent,
    answerText: answerText,
  };

  const { data: response } = await axiosInstance
    .post("records/consultations.php?action=add&type=" + type, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se přidat konzultaci");
    });
  return response;
}

export async function sendRecordAnswer(type, messageId, answerId) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };

  const payload = {
    messageId: messageId,
    answerId: answerId,
  };

  var url = "";
  switch (type) {
    case RecordType.email:
      url = "records/emails.php?action=sendAnswer";
      break;
    case RecordType.trustbox:
      url = "records/trustbox.php?action=sendAnswer";
      break;
    case RecordType.alik:
      url = "records/alik.php?action=sendAnswer";
      break;
    default:
      throw new Error("Invalid type of record: " + type);
  }

  const { data: response } = await axiosInstance
    .post(url, payload, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se odeslat odpověď");
    });
  return response;
}

export async function getTrustboxDetails(recordID) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("records/trustbox.php?detailsof=" + recordID, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se stáhnout data");
    });
  return response;
}

export async function getAlikDetails(recordID) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000,
  };
  const { data: response } = await axiosInstance
    .get("records/alik.php?detailsof=" + recordID, config)
    .catch((error) => {
      console.log(error);
      toast.error("Nepodařilo se stáhnout data");
    });
  return response;
}
