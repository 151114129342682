import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row } from "react-bootstrap";
import {
  assignToUser,
  getAssignableUsers,
} from "../../service/AssignmentService";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";

export default function AssignToUserModal(props) {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setSelectedUserId(0);
  };

  const handleAssign = () => {
    var setUser;
    if (selectedUserId !== "empty") {
      setUser = selectedUserId;
    } else {
      setUser = "";
    }
    assignToUser(props.type, setUser, props.id).then(() => {
      if (selectedUserId !== 0) {
        toast.success("Přiřazeno!");
      } else {
        toast.success("Přiřazení zrušeno!");
      }
      props.refreshParent();
    });
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      getAssignableUsers().then((response) => {
        if (response) {
          setUsers(
            response.map((u) => {
              return (
                <option value={u.ID_osoby} key={u.ID_osoby}>
                  {u.Jmeno}
                </option>
              );
            })
          );
        }
      });
    }
  }, [show]);

  return (
    <>
      <Button
        variant={"secondary"}
        style={{ marginLeft: "1px", marginRight: "1px" }}
        value="Přiřadit"
        onClick={handleShow}
        title="Přiřadit uživateli"
      >
        Přiřadit uživateli
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Přiřadit uživateli</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>Aktuálně: {props.current}</Row>
            <Form.Select
              aria-label="Assign to user selector"
              onChange={(e) => {
                setSelectedUserId(e.target.value);
              }}
            >
              <option value="empty" key="empty"></option>
              {users}
            </Form.Select>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleAssign}>
            Přiřadit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
