import ResetPasswordModal from "./modals/ResetPasswordModal";
import { Button } from "react-bootstrap";
import { deleteUser, lockUser } from "../service/UserService";
import { toast } from "react-toastify";
import { useCallback } from "react";

export default function EditUserRow(props) {
  const handleLockUser = useCallback(() => {
    var newStateBool = 1;
    console.log(props?.locked);
    if (props?.locked === "1") {
      newStateBool = 0;
    }
    lockUser(props?.login, newStateBool).then((response) => {
      if (response) {
        if (newStateBool === 1) toast.success("Zámek uživatele nastaven");
        else toast.success("Zámek uživatele odstraněn");
        props?.reloadParent();
      }
    });
  }, [props]);

  const handleDeleteUser = useCallback(() => {
    if (window.confirm("Opravdu odstranit uživatele " + props?.login)) {
      deleteUser(props?.login).then((response) => {
        if (response) {
          toast.success("Uživatel odstraněn");
          props?.reloadParent();
        }
      });
    }
  }, [props]);

  return (
    <tr>
      <td>{props.login}</td>
      <td>{props.name}</td>
      <td>{props.email}</td>
      <td>{props.role}</td>
      <td>{props.locked === "1" ? "Uživatel zakázán" : null}</td>
      <td>
        <ResetPasswordModal login={props.login}></ResetPasswordModal>
      </td>
      <td>
        <Button variant={"primary"} onClick={handleLockUser}>
          {props.locked === "1" ? "Odemčít uživatele" : "Zamčít uživatele"}
        </Button>
      </td>
      <td>
        <Button variant={"danger"} onClick={handleDeleteUser}>
          Odstranit uživatele
        </Button>
      </td>
    </tr>
  );
}
