import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import {
  forwardEmailAndSoftDelete,
  getEmailForwardTargets,
} from "../../service/RecordsService";
import { useNavigate } from "react-router-dom";

export default function ForwardToModal(props) {
  const [show, setShow] = useState(false);
  const [targets, setTargets] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState("Vyberte email");
  let navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleForward = () => {
    if (window.confirm("Opravdu chcete email přeposlat a skrýt ze systému?")) {
      if (selectedTarget !== "Vyberte email") {
        forwardEmailAndSoftDelete(props.id, selectedTarget).then(() => {
          toast.success("Přeposláno!");
          navigate("/sluzba");
        });
        setShow(false);
      } else {
        toast.warning("Nebyl vybrán cíl!");
      }
    }
  };

  useEffect(() => {
    if (show) {
      getEmailForwardTargets().then((response) => {
        if (response) {
          setTargets(
            response.map((u) => {
              return (
                <option value={u.value} key={u.value}>
                  {u.setting}
                </option>
              );
            })
          );
        }
      });
    }
  }, [show]);

  return (
    <>
      <div onClick={handleShow}>{props.children}</div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Přeposlat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              Cíl:
              <Form.Select
                aria-label="Assign to user selector"
                onChange={(e) => {
                  setSelectedTarget(e.target.value);
                }}
              >
                <option value="Vyberte email" key="emptyValue">
                  Vyberte email
                </option>
                {targets}
              </Form.Select>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleForward}>
            Přeposlat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
