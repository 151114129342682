import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { setMedId } from "../../service/MedIdService";
import { toast } from "react-toastify";

export default function MedIdModal(props) {
  const [show, setShow] = useState(false);
  const [selectedMedId, setSelectedMedId] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSetMedId = () => {
    if (selectedMedId !== 0 && selectedMedId !== props.current) {
      setMedId(selectedMedId, props.type, props.id).then(() => {
        toast.success("Med ID bylo nastaveno!");
      });
      props.refreshParent();
      setShow(false);
    }
  };

  return (
    <>
      <Button
        variant={"secondary"}
        style={{ marginLeft: "1px", marginRight: "1px" }}
        value="MED"
        onClick={handleShow}
        title="Přiřadit číso v MEDu"
      >
        #MED
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Přiřadit MED číslo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            placeholder="Číslo v MEDu"
            type="text"
            defaultValue={props.current}
            aria-label="medid"
            onChange={(e) => setSelectedMedId(e.target.value)}
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleSetMedId}>
            Přiřadit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
