import { parseJwtToken } from "../service/LoginService";
import { useCallback, useEffect, useState } from "react";

const SessionCountdown = () => {
  const tokenExpiration = parseJwtToken(
    localStorage.getItem("refreshToken")
  ).exp;
  const [remainingTime, setRemainingTime] = useState(
    Math.round(tokenExpiration - Date.now() / 1000)
  );
  const [remainingTimeString, setRemainingTimeString] = useState("");

  // User has switched back to the tab
  const onFocus = useCallback(() => {
    if (remainingTime <= 0) {
      window.location.reload();
    }
  }, [remainingTime]);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
  }, [onFocus]);

  const countRemainingTime = useCallback(() => {
    const rt = Math.round(tokenExpiration - Date.now() / 1000);
    setRemainingTime(rt);
    if (rt <= 0) {
      localStorage.removeItem("refreshToken");
    }
    setRemainingTimeString(getRemainingTimeAsString(rt));
  }, [tokenExpiration]);

  useEffect(() => {
    countRemainingTime();
    let interval = setInterval(countRemainingTime, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [countRemainingTime]);

  function getRemainingTimeAsString(inputseconds) {
    return Math.ceil(inputseconds / 60);
  }

  if (remainingTime >= 1800) {
    return <></>;
  } else if (remainingTime < 1800 && remainingTime > 0) {
    return (
      <div
        style={{ margin: "4px", color: "red" }}
        title={
          "Přihlášení brzy vyprší, pro pokračování v práci se prosím odhlašte a přihlašte zpět."
        }
      >
        Přihlášení vyprší za {remainingTimeString} minuty, uložte si
        rozpracovanou práci včas
      </div>
    );
  } else {
    window.location.reload();
    return (
      <div
        style={{ margin: "4px", color: "red" }}
        title={
          "Přihlášení vypršelo, pro pokračování v práci načtěte stránku znovu a znovu se přihlašte."
        }
      >
        Prosím načtěte stránku znovu a přihlašte se
      </div>
    );
  }
};

export default SessionCountdown;
