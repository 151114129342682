import "../App.css";
import AppNavbar from "../components/AppNavbar";
import SluzbaTopButtons from "../components/SluzbaTopButtons";
import { useEffect, useState } from "react";
import { getEmailsRecords } from "../service/RecordsService";
import { Card, Container } from "react-bootstrap";
import MlPaginatedTable from "../components/MlPaginatedTable";
import FetchMailButton from "../components/FetchMailButton";

function SluzbaEmail() {
  const [records, setRecords] = useState([]);

  const fetchRecords = () => {
    getEmailsRecords().then((response) => {
      if (response) {
        setRecords(response);
      }
    });
  };

  useEffect(() => {
    fetchRecords();
  }, [setRecords]);

  return (
    <>
      <AppNavbar></AppNavbar>
      <SluzbaTopButtons></SluzbaTopButtons>
      <Container className="Sluzba">
        <br />
        <Card>
          <Card.Header>
            <h3 style={{ color: "#188654" }}>
              Emaily{" "}
              <FetchMailButton refreshParent={fetchRecords}></FetchMailButton>
            </h3>
          </Card.Header>
        </Card>
        <MlPaginatedTable
          records={records}
          showColor={true}
          buttonVariant="success"
        ></MlPaginatedTable>
      </Container>
    </>
  );
}

export default SluzbaEmail;
