import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { logoutUser } from "../service/LoginService";
import SessionCountdown from "./SessionCountdown";

function AppNavbar(props) {
  const userName = useSelector((state) => state.userReducer.username);
  const [logoLink, setLogoLink] = useState("/sluzba");

  useEffect(() => {
    if (localStorage.getItem("userRole") === "vzdelavani")
      setLogoLink("/coursesExport");
  }, [props]);

  const logoutNow = () => {
    logoutUser().then(() => {
      window.location.href = "/";
    });
  };

  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        fixed="top"
        variant="light"
      >
        <Container>
          <Navbar.Brand as={Link} to={logoLink}>
            <img
              src="/img/home.svg"
              style={{ height: "1em", marginRight: "6px", marginBottom: "4px" }}
              title="Jít na přehled"
              alt=""
            />
            {process.env.REACT_APP_API_ENDPOINT ===
            "https://api.modralinka.cz/" ? (
              <b>
                Nástroje pro Modrou linku - pozor, nejde o testovací prostředí
              </b>
            ) : (
              <b>Nástroje pro Modrou linku - testovací prostředí</b>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <SessionCountdown></SessionCountdown>

            <Nav activeKey={window.location.pathname} variant="pills">
              {localStorage.getItem("userRole") !== "vzdelavani" ? (
                <>
                  <Nav.Item href="/">
                    <Nav.Link as={Link} to="/sluzba" title="Sluzba">
                      Služba
                    </Nav.Link>
                  </Nav.Item>
                </>
              ) : null}

              {localStorage.getItem("userRole") === "admin" ||
              localStorage.getItem("userRole") === "alikadmin" ? (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/sluzba/alikAdmin"
                      eventKey="/enterAlik"
                      title="Vložit Alíka"
                    >
                      Vložit Alíka
                    </Nav.Link>
                  </Nav.Item>
                </>
              ) : null}

              {localStorage.getItem("userRole") === "admin" ||
              localStorage.getItem("userRole") === "alikadmin" ? (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/sluzba/alikStats"
                      eventKey="/alikStats"
                      title="Alík - statistika"
                    >
                      Alík - statistika
                    </Nav.Link>
                  </Nav.Item>
                </>
              ) : null}

              {localStorage.getItem("userRole") === "admin" ||
              localStorage.getItem("userRole") === "vzdelavani" ? (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/coursesExport"
                      eventKey="/coursesExport"
                      title="Export Kurzů"
                    >
                      Export kurzů
                    </Nav.Link>
                  </Nav.Item>
                </>
              ) : null}

              <NavDropdown align="end" title={userName} id="nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/user-settings"
                  title="User-settings"
                >
                  Moje nastavení
                </NavDropdown.Item>
                {localStorage.getItem("userRole") === "admin" ? (
                  <>
                    <NavDropdown.Item as={Link} to="/users" title="Users">
                      Správa uživatelů
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/settings" title="Settings">
                      Nastavení
                    </NavDropdown.Item>
                    <NavDropdown.Divider />{" "}
                  </>
                ) : null}

                <NavDropdown.Item
                  onClick={() => {
                    logoutNow();
                  }}
                >
                  {" "}
                  Odhlásit se
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}

export default AppNavbar;
