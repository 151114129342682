import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { createNewUser } from "../../service/UserService";

export default function NewUserModal(props) {
  const [show, setShow] = useState(false);
  const [login, setLogin] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCreateNewUser = () => {
    if (login !== "" && name !== "" && email !== "" && password !== "") {
      if (password.length >= 12) {
        createNewUser(login, name, role, password, email).then(() => {
          toast.success("Uživatel uložen");
        });
        props.refreshParent();
        setShow(false);
      } else {
        toast.warning("Prosím použijte alespoň 12 znaků dlouhé heslo!");
      }
    } else {
      toast.warning("Uživatel nelze uložit, některé z polí není vyplněné");
    }
  };

  return (
    <>
      <Button
        variant="success"
        value="Přidat uživatele"
        onClick={handleShow}
        title="Přidat uživatele"
      >
        Přidat uživatele
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Přidat uživatele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>Jméno: {props.current}</Row>
            <Row>
              <Form.Control
                onChange={(e) => {
                  setName(e.target.value);
                }}
              ></Form.Control>
            </Row>
            <Row>Login:</Row>
            <Row>
              <Form.Control
                onChange={(e) => {
                  setLogin(e.target.value);
                }}
              ></Form.Control>
            </Row>
            <Row>Email:</Row>
            <Row>
              <Form.Control
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></Form.Control>
            </Row>
            <Row>Role:</Row>
            <Row>
              <Form.Select
                aria-label="Assign to user selector"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <option value="user" key="user">
                  Uživatel
                </option>
                <option value="admin" key="admin">
                  Admin
                </option>
                <option value="alikadmin" key="alikadmin">
                  Admin Alíka
                </option>
                <option value="vzdelavani" key="vzdelavani">
                  Vzdělávání
                </option>
              </Form.Select>
            </Row>
            <Row>Heslo:</Row>
            <Row>
              <Form.Control
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></Form.Control>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleCreateNewUser}>
            Vytvořit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
