import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, InputGroup } from "react-bootstrap";
import "../../App.css";
import { loginUser, validateJwtToken } from "../../service/LoginService";
import { toast } from "react-toastify";
import { downloadNewEmailRecords } from "../../service/RecordsService";
import GoogleLoginButton from "./GoogleLoginButton/GoogleLoginButton";

function getUserCredentialsFromToken(token) {
  const base64 = token.split(".")[1];
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const data = JSON.parse(jsonPayload);
  localStorage.setItem("userName", data?.name);
  localStorage.setItem("userRole", data?.role);
  localStorage.setItem("userUserId", data?.id);
}

export default function WithAuth(props) {
  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = () => {
    loginUser(enteredUsername, enteredPassword).then((response) => {
      if (response) {
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("accessToken", response.token);
        getUserCredentialsFromToken(response.token);
        if (validateJwtToken(response.token)) {
          setLoggedIn(true);
          downloadNewEmailRecords().then(() => {});
        } else {
          setLoggedIn(false);
        }
      } else {
        setValidated(true);
        toast.error("Špatné přihlašovací jméno nebo heslo");
      }
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    // handle login from google
    if (
      searchParams.get("token") !== null &&
      searchParams.get("refreshToken") !== undefined
    ) {
      localStorage.setItem("accessToken", searchParams.get("token"));
      localStorage.setItem("refreshToken", searchParams.get("refresh_token"));
      getUserCredentialsFromToken(searchParams.get("token"));
      window.location.href = "/";
    }

    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      if (validateJwtToken(refreshToken)) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    } else {
      setLoggedIn(false);
    }
  }, []);

  if (loggedIn) {
    return <>{props.children}</>;
  } else {
    return (
      <>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Container fluid="md" className={"Login-container"}>
            <h2>Přihlášení do systému Modré linky</h2>
            <Form.Group className="mb-3" controlId="formLoginUsername">
              <Form.Label>Přihlašovací jméno</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Přihlašovací jméno"
                onChange={(e) => setEnteredUsername(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Neplatný login
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLoginPassword">
              <Form.Label>Heslo</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  required
                  placeholder="Heslo"
                  onChange={(e) => setEnteredPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Neplatné heslo
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button variant="primary" onClick={handleSubmit} id="submit">
              Přihlásit se
            </Button>
          </Container>
        </Form>
        <Container>
          <center>
            <p>nebo</p>
            <GoogleLoginButton />
          </center>
        </Container>
      </>
    );
  }
}
