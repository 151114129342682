import axios from "axios";
import { refreshLogin } from "./LoginService";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 5000,
});

axiosInstance.interceptors.response.use(
  (response) => {
    // anything with 2xx response
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      if (error.response.data?.error === "Invalid User") {
        toast.error(
          "Neplatné přihlášení, zkuste to prosím znovu. Pokud problém přetrvává, kontaktujte administrátora systému."
        );
      } else {
        // console.log("Got status 401, trying to get new token");
        const originalRequest = error.config;
        const newJwt = await refreshLogin();
        originalRequest.headers["Authorization"] = "Bearer " + newJwt;
        return axios(originalRequest);
      }
    }
    if (error.response.status === 500) {
      console.log("Got status 500, error is: " + error?.response?.data?.error);
      toast.error("Chyba serveru: " + error?.response?.data?.error);
    }
    return Promise.reject(error?.response?.data);
  }
);
