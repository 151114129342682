import { Button, Modal, Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import {
  addConsultationToAnswer,
  updateRecordAnswer,
  sendRecordAnswer,
} from "../service/RecordsDetailsService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditorContainer from "./editor/EditorContainer";
import { getStatus } from "./statusAndTypeTools";
import { handleRemoveAssigneeAfterStateChange } from "../service/AssignmentService";
import RecordDetail, { RecordType } from "../models/RecordDetail";
import Answer from "../models/Answer";
import { generateReport } from "../service/ReportService";
import ToastWithReportLink from "./reports/ToastWithReportLink";

interface CFProps {
  record: RecordDetail;
  answer: Answer;
  recordType: RecordType;
  messageId: string;
  recordStatus: string;
  refreshParent: Function;
  children: any;
}

function ConsultationForm(props: CFProps) {
  const [answerAuthor, setAnswerAuthor] = useState(
    localStorage.getItem("userName") || ""
  );
  const [timeSpent, setTimeSpent] = useState(0);
  const [answerText, setAnswerText] = useState(props.answer.answer_text);
  const [consultationText, setConsultationText] = useState("");
  const [answerAuthorValidated, setAnswerAuthorValidated] = useState(true);
  const [timeSpentValidated, setTimeSpentValidated] = useState(true);
  const [answerTextValidated, setAnswerTextValidated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [preventDoubleSubmitConsultation, setPreventDoubleSubmitConsultation] =
    useState(false);
  let navigate = useNavigate();

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    if (
      validateAnswerAuthor(answerAuthor) &&
      validateAnswerText(answerText) &&
      validateTimeSpent(timeSpent)
    ) {
      setShowModal(true);
    }
  };

  const handleAuthor = (e: any) => {
    setAnswerAuthor(e.target.value);
    validateAnswerAuthor(e.target.value);
  };

  const handleTimeSpent = (e: any) => {
    setTimeSpent(e.target.value);
    validateTimeSpent(e.target.value);
  };

  const handleAnswerText = (returnedText: string) => {
    setAnswerText(returnedText);
    validateAnswerText(returnedText);
  };

  const handleConsultationText = (e: any) => {
    setConsultationText(e.target.value);
  };

  const validateAnswerAuthor = (input: string) => {
    if (input.trim() === "") {
      setAnswerAuthorValidated(false);
      return false;
    } else {
      setAnswerAuthorValidated(true);
      return true;
    }
  };

  const validateTimeSpent = (input: number) => {
    if (input > 0) {
      setTimeSpentValidated(true);
      return true;
    } else {
      setTimeSpentValidated(false);
      return false;
    }
  };

  const validateAnswerText = (input: string) => {
    if (input.trim() === "") {
      setAnswerTextValidated(false);
      return false;
    } else {
      setAnswerTextValidated(true);
      return true;
    }
  };

  const handleSaveConsultation = () => {
    // first edit answer, then save consultation
    setPreventDoubleSubmitConsultation(true);
    if (validateAnswerAuthor(answerAuthor) && validateTimeSpent(timeSpent)) {
      updateRecordAnswer(
        props.recordType,
        props.answer.answer_id,
        props.answer.answer_author,
        props.answer.time_spent,
        answerText,
        props.answer.answer_note
      ).then(() => {
        addConsultationToAnswer(
          props.recordType,
          props.answer.answer_id,
          answerAuthor,
          timeSpent,
          consultationText
        ).then(() => {
          toast.success("Konzultace uložena!");
          if (props.record.assigned_to !== "") {
            handleRemoveAssigneeAfterStateChange(
              props.recordType,
              props.messageId,
              "Konzultace uložena"
            );
          }
          props.refreshParent();
          navigate("/sluzba");
        });
      });
    } else {
      toast.info("Prosím doplňte/opravte položky ve formuláři");
      setPreventDoubleSubmitConsultation(false);
    }
  };

  const handleSendAnswer = () => {
    setDisableSend(true);
    if (
      validateAnswerAuthor(answerAuthor) &&
      validateAnswerText(answerText) &&
      validateTimeSpent(timeSpent)
    ) {
      updateRecordAnswer(
        props.recordType,
        props.answer.answer_id,
        props.answer.answer_author,
        props.answer.time_spent,
        answerText,
        props.answer.answer_note
      ).then(() => {
        addConsultationToAnswer(
          props.recordType,
          props.answer.answer_id,
          answerAuthor,
          timeSpent,
          consultationText
        ).then(() => {
          sendRecordAnswer(
            props.recordType,
            props.messageId,
            props.answer.answer_id
          ).then(() => {
            toast.success("Odpověď byla odeslána!");
            handleCloseModal();
            generateReport(props.recordType, props.messageId).then(() => {
              toast.success(
                ToastWithReportLink(
                  props.recordType,
                  props.messageId.toString()
                )
              );
            });
            props.refreshParent();
          });
        });
      });
    } else {
      toast.info("Prosím doplňte/opravte položky ve formuláři");
      setDisableSend(false);
    }
  };

  return (
    <>
      {props.answer.internal === "1" ? (
        <div>{answerText}</div>
      ) : (
        <EditorContainer
          value={answerText}
          onChange={handleAnswerText}
          isInvalid={!answerTextValidated}
        ></EditorContainer>
      )}
      {props.answer.answer_note !== "" ? (
        <div>
          {props.answer.internal !== "1" ? (
            <b>Poznámka autora {props.answer.answer_author}: </b>
          ) : (
            <b>Poznámka: </b>
          )}
          {props.answer.answer_note}
        </div>
      ) : null}
      <hr></hr>
      {props.children}
      <Row style={{ paddingTop: "1em" }}>
        <Col style={{ paddingRight: "0" }}>
          <Form.Label htmlFor="answerAuthor">
            <b>Autor konzultace:</b>
          </Form.Label>
        </Col>
        <Col>
          <Form.Control
            type="text"
            value={answerAuthor}
            id="answerAuthor"
            onChange={handleAuthor}
            isInvalid={!answerAuthorValidated}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="timeSpent">
            <b>Čas konzultace:</b>
          </Form.Label>
        </Col>
        <Col>
          <Form.Control
            type="number"
            value={timeSpent}
            id="timeSpent"
            onChange={handleTimeSpent}
            isInvalid={!timeSpentValidated}
          />
        </Col>
      </Row>
      <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
        <Form.Control
          type="text"
          as="textarea"
          value={consultationText}
          placeholder="Poznámka konzultanta"
          rows={2}
          onChange={handleConsultationText}
          id={"consultationText"}
        ></Form.Control>
      </div>
      <Button
        variant={"success"}
        style={{ marginLeft: "3px" }}
        onClick={handleSaveConsultation}
        disabled={preventDoubleSubmitConsultation}
      >
        K další konzultaci
      </Button>{" "}
      {props.recordStatus !== "Ponecháno bez odpovědi" &&
      props.recordStatus !== getStatus("neodpovidat-navrh") &&
      props.answer.internal !== "1" ? (
        <Button variant="primary" onClick={handleShowModal}>
          {props.recordType !== RecordType.alik
            ? "Odeslat klientovi"
            : "Odeslat Alíka"}
        </Button>
      ) : null}{" "}
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Odeslat odpověď klientovi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <b>Chystáte se odeslat odpověď:</b>
          </div>
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: answerText }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseModal}>
            Zrušit
          </Button>
          <Button
            variant={"primary"}
            onClick={handleSendAnswer}
            disabled={disableSend}
          >
            Odeslat tuto odpověď
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConsultationForm;
