import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { insertLinkHref } from "../MlPaginatedTable";

export default function PreviousContactsModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [previousContacts, setPreviousContacts] = useState(Array);
  const [newerContacts, setNewerContacts] = useState(Array);
  const currentRecordTime = Date.parse(props.date);

  useEffect(() => {
    if (props.previousContacts) {
      setPreviousContacts(
        props.previousContacts
          .map((contact) => {
            if (Date.parse(contact.date) < currentRecordTime) {
              return (
                <Row key={contact.messageId} style={{ paddingBottom: "2px" }}>
                  <Col>{new Date(contact.date).toLocaleString("cs-CZ")}</Col>
                  <Col>
                    <Link
                      to={insertLinkHref(props.type, contact.messageId)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="success" onClick={handleClose}>
                        Zobrazit
                      </Button>
                    </Link>
                  </Col>
                </Row>
              );
            } else return null;
          })
          .filter(Boolean)
      );
      setNewerContacts(
        props.previousContacts
          .map((contact) => {
            if (Date.parse(contact.date) > currentRecordTime) {
              return (
                <Row key={contact.messageId} style={{ paddingBottom: "2px" }}>
                  <Col>{new Date(contact.date).toLocaleString("cs-CZ")}</Col>
                  <Col>
                    <Link
                      to={insertLinkHref(props.type, contact.messageId)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="success" onClick={handleClose}>
                        Zobrazit
                      </Button>
                    </Link>
                  </Col>
                </Row>
              );
            } else return null;
          })
          .filter(Boolean)
      );
    }
  }, [props.previousContacts, props.id, props.type, currentRecordTime]);

  return (
    <>
      {props.previousContacts?.length > 0 ? (
        <Button
          variant={"warning"}
          style={{ marginRight: "1px" }}
          value="predchozi"
          onClick={handleShow}
          title="Předchozí kontakty"
        >
          Související kontakty ({props.previousContacts.length})
        </Button>
      ) : null}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Související kontakty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <b>Starší než aktuální:</b> <br />
            {previousContacts.length > 0 ? previousContacts : <p>Žádné</p>}
            <b>Novější než aktuální:</b> <br />
            {newerContacts.length > 0 ? newerContacts : <p>Žádné</p>}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zavřít
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
