import React from "react";
import { Editor, EditorState, RichUtils } from "draft-js";
import { onAddLink, createLinkDecorator } from "./Link";
import "./editorStyles.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

class EditorContainer extends React.Component {
  constructor(props) {
    super(props);
    const linkDecorator = createLinkDecorator();
    this.state = {
      editorState: props.value
        ? EditorState.createWithContent(
            stateFromHTML(props.value),
            linkDecorator
          )
        : EditorState.createEmpty(),
    };
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
    });

    this.props.onChange(stateToHTML(editorState.getCurrentContent()));
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  onUnderlineClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
    );
  };

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
  };

  onItalicClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
    );
  };

  render() {
    return (
      <div className="editorContainer">
        <button onClick={this.onUnderlineClick} title="Podtržení">
          U
        </button>
        <button onClick={this.onBoldClick} title="Tučně">
          <b>B</b>
        </button>
        <button onClick={this.onItalicClick} title="Kurzíva">
          <em>I</em>
        </button>
        <button
          onClick={() => onAddLink(this.state.editorState, this.onChange)}
        >
          Odkaz
        </button>{" "}
        <div className="editors">
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}

export default EditorContainer;
