import "./PreviousContact";
import Answer from "./Answer";
import PreviousContact from "./PreviousContact";

enum RecordType {
  alik = "alik",
  trustbox = "trustbox",
  email = "email",
}

type RecordDetail = {
  type: RecordType;
  message_id: string;
  status: string;
  date: string;
  timeLeft: number;
  id_med: string;
  assigned_to: string;
  important: string | null;
  subject: string;
  data: string;
  previousContacts: Array<PreviousContact>;
  attachments: number;
  answers: Array<Answer>;
  enteredOn: string;
  author: string;
  authorAge: string;
};

export default RecordDetail;
export { RecordType };
