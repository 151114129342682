import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import ConsultationForm from "./ConsultationForm";
import { getStatus } from "./statusAndTypeTools";
import Answer from "../models/Answer";
import RecordDetail, { RecordType } from "../models/RecordDetail";

interface AAProps {
  answer: Answer;
  record: RecordDetail;
  recordType: RecordType;
  index: number;
  refreshParent: Function;
}

function AnswerAccordion(props: AAProps) {
  const [consultations, setConsultations] = useState<JSX.Element[]>([]);
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    setConsultations(
      props.answer.consultations.map((consultation, index) => {
        return (
          <div
            key={"consultation" + (index + 1)}
            style={{ paddingTop: "0.5em" }}
          >
            <b>Konzultace {index + 1} od:</b> {consultation.consultation_author}
            , <b>čas:</b> {consultation.time_spent} min.
            {consultation.consultation_text !== "" ? (
              <>
                <b>, poznámka: </b> {consultation.consultation_text}
              </>
            ) : null}
            <br />
            <br />
          </div>
        );
      })
    );
  }, [props]);

  const hideConsultationFormAndRefresh = () => {
    setFormOpen(false);
    props.refreshParent();
  };

  const nonEditableAnswer = (
    <>
      <div
        className="answerAccordionText"
        dangerouslySetInnerHTML={{ __html: props.answer.answer_text }}
      ></div>
      {props.answer.answer_note !== "" ? (
        <div
          style={{ paddingTop: "0.5em" }}
          className="answerAccordionAuthorNote"
        >
          {props.answer.internal !== "1" ? (
            <b>Poznámka autora {props.answer.answer_author}: </b>
          ) : (
            <b>Poznámka: </b>
          )}
          {props.answer.answer_note}
        </div>
      ) : null}
      <hr></hr>
    </>
  );

  const notSentStatusButtons = (
    <>
      {formOpen ? (
        <ConsultationForm
          record={props.record}
          recordType={props.recordType}
          recordStatus={props.record.status}
          messageId={props.record.message_id}
          answer={props.answer}
          refreshParent={hideConsultationFormAndRefresh}
        >
          {consultations}
        </ConsultationForm>
      ) : null}
      {!formOpen ? (
        props.record.status !== getStatus("neodpovidat") ? (
          <Button
            variant={"success"}
            onClick={() => {
              setFormOpen(true);
            }}
          >
            {props.answer.internal !== "1"
              ? "Konzultovat tuto odpověď"
              : "Konzultovat rozhodnutí"}
          </Button>
        ) : null
      ) : (
        <Button
          variant={"warning"}
          onClick={() => {
            setFormOpen(false);
          }}
        >
          Zrušit
        </Button>
      )}{" "}
    </>
  );

  return (
    <Accordion defaultActiveKey={["0"]} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Container>
            <Row>
              <Col>
                <b>
                  {props.answer.internal !== "1"
                    ? "Odpověď"
                    : "Návrh na ponechání bez odpovědi"}{" "}
                  {props.index} od:{" "}
                </b>{" "}
                &nbsp;
                {props.answer.answer_author}
                ,&nbsp;
                <b>čas odpovědi:</b>&nbsp;{props.answer.time_spent} minut
              </Col>

              {props.answer.use_this_answer === "1" ? (
                <Col style={{ textAlign: "right" }}>
                  <b>Odpověď odeslaná klientovi</b>
                </Col>
              ) : null}
            </Row>
          </Container>
        </Accordion.Header>
        <Accordion.Body>
          {formOpen ? null : nonEditableAnswer}
          {formOpen ? null : consultations}
          {props.record.status !== getStatus("odeslano")
            ? notSentStatusButtons
            : null}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AnswerAccordion;
