import { axiosInstance } from "./SetupAxios";

export async function getSettingsList() {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { data: response } = await axiosInstance
    .get("settings/index.php?action=list", config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}

export async function setSettingsValue(setting, value) {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let payload = { setting: setting, value: value };

  const { data: response } = await axiosInstance
    .post("settings/index.php?action=set", payload, config)
    .catch((error) => {
      console.log(error);
    });
  return response;
}
