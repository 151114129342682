import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  addAnswerToRecord,
  setRecordStatus,
  updateRecordAnswer,
} from "../service/RecordsDetailsService";
import { handleRemoveAssigneeAfterStateChange } from "../service/AssignmentService";
import { toast } from "react-toastify";
import EditorContainer from "./editor/EditorContainer";
import { useNavigate } from "react-router-dom";
import RecordDetail, { RecordType } from "../models/RecordDetail";

interface EAAProps {
  record: RecordDetail;
  type: RecordType;
  recordId: string;
  answer?: {
    answer_id: string;
    answer_author: string;
    time_spent: number;
    answer_text: string;
    answer_note: string;
  };
  refreshParent: Function;
}

function EditableAnswerAccordion(props: EAAProps) {
  const [answerAuthor, setAnswerAuthor] = useState(
    localStorage.getItem("userName") || ""
  );
  const [answerAuthorValidated, setAnswerAuthorValidated] = useState(true);
  const [timeSpent, setTimeSpent] = useState(0);
  const [timeSpentValidated, setTimeSpentValidated] = useState(true);
  const [answerText, setAnswerText] = useState("");
  const [answerTextValidated, setAnswerTextValidated] = useState(true);
  const [answerNote, setAnswerNote] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [disableSubmitToConsultation, setDisableSubmitToConsultation] =
    useState(false);
  const [disableTemporarySave, setDisableTemporarySave] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (props.answer) {
      setAnswerAuthor(props.answer.answer_author);
      setTimeSpent(props.answer.time_spent);
      setAnswerText(props.answer.answer_text);
      setAnswerNote(props.answer.answer_note);
    }
  }, [props]);

  const handleAuthor = (e: any) => {
    setAnswerAuthor(e.target.value);
    validateAnswerAuthor(e.target.value);
  };

  const handleTimeSpent = (e: any) => {
    setTimeSpent(e.target.value);
    validateTimeSpent(e.target.value);
  };

  const handleAnswerText = (returnedText: string) => {
    setAnswerText(returnedText);
    validateAnswerText(returnedText);
  };

  const handleNote = (e: any) => {
    setAnswerNote(e.target.value);
  };

  const validateAnswerAuthor = (input: string) => {
    if (input.trim() === "") {
      setAnswerAuthorValidated(false);
      return false;
    } else {
      setAnswerAuthorValidated(true);
      return true;
    }
  };

  const validateTimeSpent = (input: number) => {
    if (input > 0) {
      setTimeSpentValidated(true);
      return true;
    } else {
      setTimeSpentValidated(false);
      return false;
    }
  };

  const validateAnswerText = (input: string) => {
    if (input.trim() === "") {
      setAnswerTextValidated(false);
      return false;
    } else {
      setAnswerTextValidated(true);
      return true;
    }
  };

  const handleTemporarySave = () => {
    setDisableTemporarySave(true);
    setDisableSubmitToConsultation(true);
    // add new or edit last answer
    if (
      validateAnswerAuthor(answerAuthor) &&
      validateTimeSpent(timeSpent) &&
      validateAnswerText(answerText)
    ) {
      if (!props.answer) {
        addAnswerToRecord(
          props.type,
          props.recordId,
          answerAuthor,
          timeSpent,
          answerText,
          answerNote
        ).then(() => {
          setRecordStatus(props.type, props.recordId, "rozpracovano").then(
            () => {
              toast.success("Průběžně uloženo!");
              props.refreshParent();
              setDisableTemporarySave(false);
              setDisableSubmitToConsultation(false);
            }
          );
        });
      } else {
        updateRecordAnswer(
          props.type,
          props.answer.answer_id,
          answerAuthor,
          timeSpent,
          answerText,
          answerNote
        ).then(() => {
          toast.success("Průběžně uloženo!");
          props.refreshParent();
          setDisableTemporarySave(false);
          setDisableSubmitToConsultation(false);
        });
      }
    } else {
      toast.info("Prosím doplňte/opravte položky ve formuláři");
      setDisableTemporarySave(false);
      setDisableSubmitToConsultation(false);
    }
  };

  const handleSubmitSave = () => {
    // add new or edit last answer
    setDisableSubmitToConsultation(true);
    setDisableTemporarySave(true);
    if (
      validateAnswerAuthor(answerAuthor) &&
      validateTimeSpent(timeSpent) &&
      validateAnswerText(answerText)
    ) {
      if (!props.answer) {
        addAnswerToRecord(
          props.type,
          props.recordId,
          answerAuthor,
          timeSpent,
          answerText,
          answerNote
        ).then(() => {
          setRecordStatus(props.type, props.recordId, "kekonzultaci").then(
            () => {
              toast.success("Předáno ke konzultaci!");
              if (props.record.assigned_to !== "") {
                handleRemoveAssigneeAfterStateChange(
                  props.type,
                  props.recordId,
                  "Předáno ke konzultaci"
                );
              }
              props.refreshParent();
              setDisableSubmitToConsultation(false);
              setDisableTemporarySave(false);
              navigate("/sluzba");
            }
          );
        });
      } else {
        updateRecordAnswer(
          props.type,
          props.answer.answer_id,
          answerAuthor,
          timeSpent,
          answerText,
          answerNote
        ).then(() => {
          setRecordStatus(props.type, props.recordId, "kekonzultaci").then(
            () => {
              toast.success("Předáno ke konzultaci!");
              if (props.record.assigned_to !== "") {
                handleRemoveAssigneeAfterStateChange(
                  props.type,
                  props.recordId,
                  "Předáno ke konzultaci"
                );
              }
              props.refreshParent();
              setDisableSubmitToConsultation(false);
              setDisableTemporarySave(false);
              navigate("/sluzba");
            }
          );
        });
      }
    } else if (
      validateAnswerAuthor(answerAuthor) &&
      validateTimeSpent(timeSpent)
    ) {
      setIsOpen(true);
    } else {
      toast.info("Prosím doplňte/opravte položky ve formuláři");
      setDisableSubmitToConsultation(false);
      setDisableTemporarySave(false);
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmitSaveEmptyAnswer = () => {
    setDisableSubmitToConsultation(true);
    setDisableTemporarySave(true);
    addAnswerToRecord(
      props.type,
      props.recordId,
      answerAuthor,
      timeSpent,
      answerText,
      answerNote
    ).then(() => {
      setRecordStatus(props.type, props.recordId, "kekonzultaci").then(() => {
        toast.success("Předáno ke konzultaci!");
        if (props.record.assigned_to !== "") {
          handleRemoveAssigneeAfterStateChange(
            props.type,
            props.recordId,
            "Předáno ke konzultaci"
          );
        }
        props.refreshParent();
        setDisableSubmitToConsultation(false);
        setDisableTemporarySave(false);
        navigate("/sluzba");
      });
    });
  };

  return (
    <Accordion defaultActiveKey={["0"]} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <b>{props.answer ? "Rozpracovaná odpověď" : "Odpověď"}</b>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col style={{ paddingRight: "0" }}>
              <Form.Label htmlFor="answerAuthor">Autor odpovědi:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                isInvalid={!answerAuthorValidated}
                type="text"
                value={answerAuthor}
                id="answerAuthor"
                onChange={handleAuthor}
              />
            </Col>
            <Col>
              <Form.Label htmlFor="timeSpent">Čas odpovědi:</Form.Label>
            </Col>
            <Col>
              <Form.Control
                isInvalid={!timeSpentValidated}
                type="number"
                value={timeSpent}
                id="timeSpent"
                onChange={handleTimeSpent}
              />
            </Col>
          </Row>
          <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
            {answerText !== "" ? (
              <EditorContainer
                value={answerText}
                onChange={handleAnswerText}
                isInvalid={!answerTextValidated}
              ></EditorContainer>
            ) : (
              <EditorContainer
                value={props?.answer?.answer_text}
                onChange={handleAnswerText}
                isInvalid={!answerTextValidated}
              ></EditorContainer>
            )}
          </div>
          <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
            <div style={{ paddingTop: "0.5em" }}>
              <b>Poznámka autora: </b>
            </div>
            <Form.Control
              type="text"
              as="textarea"
              value={answerNote}
              rows={2}
              onChange={handleNote}
              id={"answerNote"}
            ></Form.Control>
          </div>
          <Button
            variant="success"
            onClick={handleTemporarySave}
            disabled={disableTemporarySave}
            id={"saveDraft"}
          >
            Průběžně uložit
          </Button>{" "}
          <Button
            variant="primary"
            onClick={handleSubmitSave}
            disabled={disableSubmitToConsultation}
            id={"saveAndSubmit"}
          >
            Předat ke konzultaci
          </Button>
          <Modal show={modalIsOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Upozornění</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Opravdu chcete předat ke konzultaci s prázdnou odpovědí?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Zavřít
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmitSaveEmptyAnswer}
                disabled={disableSubmitToConsultation}
              >
                Předat ke konzultaci
              </Button>
            </Modal.Footer>
          </Modal>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default EditableAnswerAccordion;
