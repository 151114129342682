//Add Link Component
import React from "react";
import { CompositeDecorator, EditorState, Modifier } from "draft-js";

const Link = ({ entityKey, contentState, children }) => {
  let { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      style={{ color: "blue", fontStyle: "italic" }}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={url}
    >
      {children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

// call all together
export const onAddLink = (editorState, setEditorState) => {
  const decorator = createLinkDecorator();
  let linkUrl = window.prompt("Vložte odkaz (včetně http:// ):", "https://");
  if (linkUrl) {
    const currentContent = editorState.getCurrentContent();
    //const createEntity =
    currentContent.createEntity("LINK", "MUTABLE", {
      url: linkUrl,
    });
    let entityKey = currentContent.getLastCreatedEntityKey();
    const selection = editorState.getSelection();
    // if text is selected, make link from selected text, else insert new link
    if (selection.isCollapsed()) {
      const textWithEntity = Modifier.insertText(
        currentContent,
        selection,
        linkUrl,
        null,
        entityKey
      );
      let newState = EditorState.createWithContent(textWithEntity, decorator);
      setEditorState(newState);
    } else {
      const textWithEntity = Modifier.applyEntity(
        currentContent,
        selection,
        entityKey
      );
      let newState = EditorState.createWithContent(textWithEntity, decorator);
      setEditorState(newState);
    }
  }
};
