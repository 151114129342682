import "./App.css";
import AppNavbar from "./components/AppNavbar";
import { Container } from "react-bootstrap";

function App() {
  const role = localStorage.getItem("userRole");

  return (
    <div className="App">
      <AppNavbar></AppNavbar>
      <Container>
        <h1>Vítejte v nástrojích pro databázi Modré Linky</h1>

        <p>
          Podle vám přidělené role máte přístup k různým nástrojům na této
          stránce.
        </p>
        <p>
          Vaše role je: <b>{role}</b>
        </p>
        <p>
          Pokud zaznamenáte jakýkoliv problém, nebo budete mít jakoukloliv
          připomínku, kontaktujte Matěje Pokorného na adrese{" "}
          <a href="mailto:matej.pokorny@modralinka.cz">
            matej.pokorny@modralinka.cz
          </a>
          .
        </p>

        <h2>Historie změn</h2>
        <i>29.10.2024</i>
        <ul>
          <li>Modul export kurzů nyní umí pracovat s kapacitou kurzů.</li>
        </ul>

        <i>7.10.2024</i>
        <ul>
          <li>Oprava - zmizely i další admin možnosti z menu.</li>
        </ul>

        <i>6.10.2024</i>
        <ul>
          <li>
            Odstranění možnosti aktualizace webu modralinka.cz - po změnách na
            hostingu již nejde touto metodou vynutit aktualizaci.
          </li>
        </ul>

        <i>5.10.2024</i>
        <ul>
          <li>Oprava chyby při odesílání odpovědi s apostrofem.</li>
        </ul>

        <i>30.9.2024</i>
        <ul>
          <li>Rozšíření logování na stránce schránky důvěry.</li>
        </ul>

        <i>13.5.2024</i>
        <ul>
          <li>Generování záznamu pro MED při odeslání emailu/SIPu/Alíka.</li>
          <li>Možnost neuvádět věk při vkládání Alíka.</li>
          <li>
            Přihlášení pomocí google. Pokud má váš uživatelský účet modrolinkový
            email jako přihlašovací jméno, je možné se do systému přihlásit
            pomocí modrolinkového google přihlášení. Dostanete se do stejného
            účtu, jako při klasickém přihlášení pomocí jména a hesla.
          </li>
        </ul>

        <i>23.1.2024</i>
        <ul>
          <li>Přidání stránky zápisy</li>
        </ul>

        <i>14.1.2024</i>
        <ul>
          <li>Oprava zobrazení poznámky vkládajícího Alíka</li>
        </ul>

        <i>30.12.2023</i>
        <ul>
          <li>Oprava dotazů na zrušení přiřazení u nepřiřazených záznamů</li>
        </ul>

        <i>10.12.2023</i>
        <ul>
          <li>Oprava zobrazení rozhraní vkládání Alíka</li>
          <li>
            Dotaz na odstranění přiřazení operátora po předání ke konzultaci
          </li>
          <li>Oprava výpočtu zbývajícího času na odpověď u alíku</li>
        </ul>

        <i>4.12.2023</i>
        <ul>
          <li>Seřazení jmen v přiřazování</li>
          <li>Oprava zrušení přiřazení</li>
        </ul>

        <i>1.12.2023</i>
        <ul>
          <li>Zvýšení stability přihlašování</li>
        </ul>

        <i>1.12.2023</i>
        <ul>
          <li>Přidání Alíka do systému</li>
        </ul>

        <i>13.10.2023</i>
        <ul>
          <li>vylepšení zobrazení exportu účastníků kurzů</li>
        </ul>

        <i>31.7.2023</i>
        <ul>
          <li>Aktualizace na node.js 18</li>
        </ul>

        <i>3.6.2023</i>
        <ul>
          <li>Změna formátu dat na yyyy. mm. dd hh:MM:ss</li>
        </ul>

        <i>23.5.2023</i>
        <ul>
          <li>Oprava vícenásobného odesílání zpráv a konzultací</li>
        </ul>

        <i>18.5.2023</i>
        <ul>
          <li>Automatické odhlášení po vypršení přihlášení</li>
        </ul>

        <i>10.5.2023</i>
        <ul>
          <li>
            V editoru odpovědi nyní funguje zvýraznění překlepů a gramatických
            chyb
          </li>
        </ul>

        <i>15.4.2023</i>
        <ul>
          <li>Oprava špatně zobrazených uvozovek v konzultacích</li>
          <li>Přidána možnost přeposlat AJ týmu</li>
        </ul>

        <i>25.3.2023</i>
        <ul>
          <li>Nová sekce "Moje nastavení", umožňující reset hesla</li>
        </ul>

        <i>20.3.2023</i>
        <ul>
          <li>Zákaz konzultací ve stavu "Ponecháno bez odpovědi"</li>
          <li>Oprava odstraňování uživatelů</li>
        </ul>

        <i>11.3.2023</i>
        <ul>
          <li>Opravy překlepů a drobností</li>
          <li>Možnost odstranění a zamčení uživatelů pro administrátory</li>
          <li>Zamezení dvojitému odeslání konzultace</li>
        </ul>

        <i>5.3.2023</i>
        <ul>
          <li>Drobné vylepšení a opravy ve správě kurzů</li>
          <li>Více informací u nepovedeného přilhášení</li>
        </ul>

        <i>25.2.2023</i>
        <ul>
          <li>Opraveno zobrazení "žádné" v souvisejících kontaktech.</li>
          <li>Opravena chyba kdy se mohla odpověď odeslat 2x</li>
          <li>
            Opravena role vzdělávání - vidí export kurzů, nemá přístup ke službě
          </li>
          <li>Přidáno odsazení hlavičky do přeposílaných emailů</li>
          <li>Skrýt další akce po uzavření zprávy</li>
          <li>Opraven průchod při neodpovídání u SIPu</li>
        </ul>

        <i>22.2.2023</i>
        <ul>
          <li>
            Opraveno "zaseknutí" zprávy při nepovedeném přeposlání ze systému
          </li>
          <li>Nové menu "Více akcí"</li>
          <li>Konkrétnější chybové hlášky v případě chyby</li>
          <li>Přidán stav "navrženo k ponechání bez odpovědi"</li>
          <li>
            Přidány interní odpovědi, které není možné odeslat, pro možnost
            diskuse
          </li>
        </ul>

        <i>31.12.2022</i>
        <ul>
          <li>Opraveno zobrazování falešně pozitivních zpráv při chybě</li>
          <li>Export kurzů nyní umí exportovat osvědčení v pdf</li>
          <li>
            30 minut před vypršením přihlášení je zobrazen časovač s informací o
            vypršení přihlášení
          </li>
        </ul>

        <i>30.11.2022</i>
        <ul>
          <li>Zobrazení náhledu kam vede odkaz v editoru odpovědi</li>
          <li>Zvětšení mezer mezi odstavci v editoru odpovědi</li>
        </ul>

        <i>26.11.2022</i>
        <ul>
          <li>Ověření před přeposláním/označením jako spam</li>
          <li>
            Přeposlání/označačení jako spam přesměruje uživatele na přehled
            služby
          </li>
          <li>
            Označačení "neodpovídat" přesměruje uživatele na přehled služby
          </li>
          <li>Skrytí prázdné poznámky u odeslané odovědi</li>
          <li>Ověření před označením "neodpovídat"</li>
        </ul>

        <i>18.11.2022</i>
        <ul>
          <li>Přihlašovací jméno rozšířeno z 20 na 60 znaků</li>
          <li>Do formuláře se nyní předvyplňuje jméno místo loginu</li>
          <li>
            Talčítko přeposlat, po přeposlání je zpráva ze systému zkryta, cíle
            jsou Hanka, Hanka, Tom a 2 kanály ve slacku (spam a vzdělávání)
          </li>
          <li>
            Ve stavu Ponecháno bez odpovědi je skryto tlačíko odeslat klientovi,
            možnost konzultovat - diskutovat zůstává bez rizika odeslání
          </li>
          <li>Označování důležitosti emailu a SIPu funguje</li>
        </ul>

        <i>11.11.2022</i>
        <ul>
          <li>
            Přidána stránka nastavení a správy uživatelů - pouze pro
            administrátory
          </li>
          <li>Opraven formát MED id</li>
          <li>
            Po každém přihlášení do aplikace se zkontroluje nová pošta v gmailu
          </li>
          <li>
            Po stažení emailů pomocí tlačítka stáhnout novou poštu se seznam
            automaticky obnoví
          </li>
          <li>
            Po přiřazení operátorovi se automaticky zobrazí v hlavičce bez
            znovunačtení stránky
          </li>
        </ul>

        <i>29.10.2022</i>
        <ul>
          <li>
            Po předání ke konzultaci se nezmění status v přehledu - opraveno
          </li>
          <li>
            Pokud není při vkládání odkazu ozanačen text, vloží se odkaz s
            textem odkazu samotného
          </li>
        </ul>

        <i>21.10.2022</i>

        <ul>
          {" "}
          <li>Odsazení konzultací od tlačítka</li>
          <li>Skrytí prázdné poznámky autora</li>
          <li>
            Zvolená odpověď přejmenováno na odpověď odeslaná klientovi,
            zarovnáno doprava
          </li>
          <li>
            Předchozí kontakty přejmenovány na související kontakty (mohou zde
            být i novější, než je aktuální zobrazený)
          </li>
          <li>
            Třízení souvisejících kontaktů na starší a novější než aktuální
          </li>
          <li>Filtrování kurzů pouze na pulbikované, změna zobrazení</li>
          <li>Opraveno přidávání konzultací k SIPu</li>
          <li>Opraveno počítání časového limitu na odpověď</li>
          <li>
            Zobrazení jména autora i u poznámky - "Poznámka autora Jméno: "
          </li>
          <li>Zvýraznění tlačítka pro stažení emailů</li>
          <li>
            Automatická detekce a zobrazení testovacího a produkčního prostředí
          </li>
        </ul>
      </Container>
    </div>
  );
}

export default App;
