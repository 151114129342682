import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { Container } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import EditUserRow from "../components/EditUserRow";
import { getUserList } from "../service/UserService";
import NewUserModal from "../components/modals/NewUserModal";
import Table from "react-bootstrap/Table";

function Users() {
  var [users, setUsers] = useState([]);

  const getUsers = useCallback(() => {
    getUserList().then((response) => {
      if (response) {
        setUsers(response);
      }
    });
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container className="Users">
        <h1>Správa uživatelů</h1>
        <NewUserModal refreshParent={getUsers}></NewUserModal>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Login</th>
              <th>Jméno</th>
              <th>Email</th>
              <th>Role</th>
              <th>Uživatel zakázán</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((u) => {
              return (
                <EditUserRow
                  login={u.Login}
                  name={u.Jmeno}
                  id={u.ID_osoby}
                  role={u.Role}
                  email={u.Email}
                  locked={u.locked}
                  key={u.Login}
                  reloadParent={getUsers}
                ></EditUserRow>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}

export default Users;
