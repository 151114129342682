import { RecordType } from "../models/RecordDetail";

export const insertColor = (type) => {
  let color;
  if (type === RecordType.email) {
    color = "red";
  } else if (type === RecordType.trustbox) {
    color = "blue";
  } else if (type === RecordType.alik) {
    color = "yellow";
  }
  return (
    <span
      style={{
        backgroundColor: color,
        width: "10px",
        display: "inline-block",
        color: color,
        marginRight: "5px",
        borderColor: "white",
        borderStyle: "solid",
        borderWidth: "thin",
      }}
    >
      .
    </span>
  );
};

export const getType = (type) => {
  if (type === RecordType.email) {
    return "E-mail";
  } else if (type === RecordType.trustbox) {
    return "SIP";
  } else if (type === RecordType.alik) {
    return "Alík";
  }
};

export const getStatus = (status) => {
  switch (status) {
    case "novy":
      return "Nový";
    case "kekonzultaci":
      return "Ke konzultaci";
    case "rozpracovano":
      return "Rozpracováno";
    case "novaodpoved":
      return "Nová odpověď";
    case "odeslano":
      return "Odesláno";
    case "neodpovidat-navrh":
      return "Navrženo k ponechání bez odpovědi";
    case "neodpovidat":
      return "Ponecháno bez odpovědi";
    default:
      return status;
  }
};
