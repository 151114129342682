import "../App.css";
import AssignToUserModal from "./modals/AssignToUserModal";
import MedIdModal from "./modals/MedIdModal";
import { getStatus, getType, insertColor } from "./statusAndTypeTools";
import PreviousContactsModal from "./modals/PreviousContactsModal";
import MoreActionsMenu from "./MoreActionsMenu";
import {
  setEmailImportance,
  setTrustboxImportance,
  setAlikImportance,
} from "../service/RecordsService";
import { toast } from "react-toastify";
import RecordDetail, { RecordType } from "../models/RecordDetail";
import Button from "react-bootstrap/Button";

function setUrgentOrReset(
  recordType: string,
  currentState: string | null,
  id: string,
  refreshParent: Function
) {
  let newState = "urgent";
  if (currentState === "urgent") {
    newState = "normal";
  }
  if (recordType === RecordType.email) {
    setEmailImportance(id, newState).then(() => {
      if (newState === "urgent") {
        toast.success("Označeno jako urgentní!");
        refreshParent();
      } else {
        toast.warning("Označení urgentní zrušeno!");
        refreshParent();
      }
    });
  } else if (recordType === RecordType.trustbox) {
    setTrustboxImportance(id, newState).then(() => {
      if (newState === "urgent") {
        toast.success("Označeno jako urgentní!");
        refreshParent();
      } else {
        toast.warning("Označení urgentní zrušeno!");
        refreshParent();
      }
    });
  } else if (recordType === RecordType.alik) {
    setAlikImportance(id, newState).then(() => {
      if (newState === "urgent") {
        toast.success("Označeno jako urgentní!");
        refreshParent();
      } else {
        toast.warning("Označení urgentní zrušeno!");
        refreshParent();
      }
    });
  }
}

function setImportantOrReset(
  recordType: string,
  currentState: string | null,
  id: string,
  refreshParent: Function
) {
  var newState = "important";
  if (currentState === "important") {
    newState = "normal";
  }
  if (recordType === RecordType.email) {
    setEmailImportance(id, newState).then(() => {
      if (newState === "important") {
        toast.success("Označeno jako důležité!");
        refreshParent();
      } else {
        toast.warning("Označení důležité zrušeno!");
        refreshParent();
      }
    });
  } else if (recordType === RecordType.trustbox) {
    setTrustboxImportance(id, newState).then(() => {
      if (newState === "important") {
        toast.success("Označeno jako důležité!");
        refreshParent();
      } else {
        toast.warning("Označení důležité zrušeno!");
        refreshParent();
      }
    });
  } else if (recordType === RecordType.alik) {
    setAlikImportance(id, newState).then(() => {
      if (newState === "important") {
        toast.success("Označeno jako důležité!");
        refreshParent();
      } else {
        toast.warning("Označení důležité zrušeno!");
        refreshParent();
      }
    });
  }
}

interface RecordHeaderProps {
  record: RecordDetail;
  id: string;
  refreshParent: Function;
}

function RecordHeader(props: RecordHeaderProps) {
  return (
    <>
      <h4>
        {insertColor(props.record.type)}
        {getType(props.record.type)}: {getStatus(props.record.status)}
      </h4>
      {props.record.type !== RecordType.alik ? (
        <>
          <b>Doručeno:</b> {new Date(props.record.date).toLocaleString("cs-CZ")}
        </>
      ) : (
        <>
          <b>Vloženo do systému:</b>{" "}
          {new Date(props.record.date).toLocaleString("cs-CZ")}
          <span title="Datum vložení záznamu do systému alik.cz">
            <b> Vloženo do alik.cz: </b>
            {new Date(props.record.enteredOn).toLocaleDateString("cs-CZ")}{" "}
          </span>
        </>
      )}
      <b> Odeslat do:</b> {props.record.timeLeft} hodin
      {props.record.id_med !== "" ? (
        <>
          <b> ID MED:</b> {props.record.id_med}
        </>
      ) : null}
      <span id="buttons" style={{ float: "right" }}>
        <Button
          variant={"secondary"}
          style={{ marginLeft: "1px", marginRight: "1px" }}
          title="Označit jako důležité"
          onClick={() =>
            setImportantOrReset(
              props.record.type,
              props.record.important,
              props.id,
              props.refreshParent
            )
          }
        >
          <img
            src="/img/Warning-yellow.png"
            style={{ height: "20px" }}
            alt=""
          />
        </Button>
        <Button
          style={{ marginLeft: "1px", marginRight: "1px" }}
          variant={"secondary"}
          onClick={() =>
            setUrgentOrReset(
              props.record.type,
              props.record.important,
              props.id,
              props.refreshParent
            )
          }
          title="Označit jako urgentní"
        >
          <img src="/img/Warning-red.png" style={{ height: "20px" }} alt="" />
        </Button>

        <MedIdModal
          id={props.id}
          type={props.record.type}
          current={props.record.id_med}
          refreshParent={props.refreshParent}
        ></MedIdModal>
        {/*
        <NutModal id={ id} type={  props.record.type}></NutModal> */}

        <AssignToUserModal
          id={props.id}
          type={props.record.type}
          current={props.record.assigned_to}
          refreshParent={props.refreshParent}
        ></AssignToUserModal>

        <PreviousContactsModal
          previousContacts={props.record.previousContacts}
          type={props.record.type}
          date={props.record.date}
        ></PreviousContactsModal>
        {props.record.status !== getStatus("neodpovidat") &&
        props.record.status !== getStatus("odeslano") ? (
          <MoreActionsMenu record={props.record}></MoreActionsMenu>
        ) : null}
      </span>
      <br />
      {props.record.type === RecordType.email ? (
        <>
          <b>Předmět:</b> {props.record.subject}
        </>
      ) : null}
      {(props.record.attachments as number) > 0 ? (
        <>
          <b> Počet příloh: </b>
          <div style={{ color: "red" }}>
            <b>{props.record.attachments}</b> (přílohy se z bezpečnostních
            důvodů nezobrazují)
          </div>
        </>
      ) : null}
      {props.record.assigned_to !== "" ? (
        <>
          {" "}
          <b>Přiřazeno:</b> {props.record.assigned_to}
        </>
      ) : null}
      {props.record.important === "important" ? (
        <>
          {" "}
          <b style={{ color: "orange" }}>Důležité</b>
        </>
      ) : null}
      {props.record.important === "urgent" ? (
        <>
          {" "}
          <b style={{ color: "red" }}>Urgentní</b>
        </>
      ) : null}
    </>
  );
}

export default RecordHeader;
