import "../App.css";
import AppNavbar from "../components/AppNavbar";
import { useCallback, useEffect, useState } from "react";
import {
  setRecordStatus,
  getTrustboxDetails,
  addAnswerToRecord,
} from "../service/RecordsDetailsService";
import { Button, Card, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import RecordHeader from "../components/RecordHeader.tsx";
import AnswerAccordion from "../components/AnswerAccordion";
import EditableAnswerAccordion from "../components/EditableAnswerAccordion";
import { toast } from "react-toastify";
import { getStatus } from "../components/statusAndTypeTools";
import { RecordType } from "../models/RecordDetail";

function TrustboxDetail() {
  const [record, setRecord] = useState([]);
  const [answerAccordions, setAnswerAccordions] = useState("");
  const [disableAddNewAnswer, setDiableAddNewAnswer] = useState(false);
  const { recordId } = useParams();
  const navigate = useNavigate();

  const loadRecord = useCallback(() => {
    getTrustboxDetails(recordId).then((response) => {
      if (response) {
        setRecord(response);
      }
    });
  }, [setRecord, recordId]);

  useEffect(() => {
    loadRecord();
  }, [loadRecord]);

  useEffect(() => {
    if (record.answers) {
      setAnswerAccordions(
        record.answers.map((answer, index) => {
          return (
            <div key={"answer" + (index + 1)}>
              <br />
              <AnswerAccordion
                answer={answer}
                recordType={RecordType.trustbox}
                record={record}
                index={index + 1}
                refreshParent={loadRecord}
              ></AnswerAccordion>
            </div>
          );
        })
      );
    }
  }, [record, loadRecord]);

  const handleAddNewAnswer = () => {
    setDiableAddNewAnswer(true);
    addAnswerToRecord(
      RecordType.trustbox,
      record.message_id,
      localStorage.getItem("userName"),
      "0",
      "Nová odpověď",
      ""
    ).then(() => {
      setRecordStatus(
        RecordType.trustbox,
        record.message_id,
        "novaodpoved"
      ).then(() => {
        toast.success("Nová odpověd připravena!");
        loadRecord();
        setDiableAddNewAnswer(false);
      });
    });
  };

  const handleFinalDontAnswer = () => {
    if (window.confirm("Opravdu definitivně označit jako neodpovídat?")) {
      setRecordStatus(record.type, record.message_id, "neodpovidat").then(
        () => {
          toast.success("Označeno jako neodpovídat!");
          navigate("/sluzba");
        }
      );
    }
  };

  const handleGoBackToConsultations = () => {
    if (window.confirm("Opravdu vrátit ke konzultacím?")) {
      setRecordStatus(record.type, record.message_id, "kekonzultaci").then(
        () => {
          toast.success("Vráceno ke konzultaci!");
          navigate("/sluzba");
        }
      );
    }
  };

  useEffect(() => {
    console.log(record.status, getStatus("neodpovidat-navrh"));
  }, [record]);

  return (
    <>
      <AppNavbar></AppNavbar>
      <Container className="SluzbaDetail">
        <br />
        <Card>
          <Card.Header>
            <RecordHeader
              record={record}
              refreshParent={loadRecord}
              id={record.message_id}
            />
          </Card.Header>
          <Card.Body>
            <div
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: record.data }}
            />
            <hr />
            {record.status === getStatus("kekonzultaci") ? (
              <Button
                variant="primary"
                onClick={handleAddNewAnswer}
                disabled={disableAddNewAnswer}
              >
                Přidat novou odpověď
              </Button>
            ) : null}{" "}
          </Card.Body>
        </Card>
        {record.status === getStatus("neodpovidat-navrh") ? (
          <Card>
            <Card.Body>
              <h5>
                Tato zpráva byla navržena k ponechání bez odpovědi. V tomto
                stavu nelze odeslat odpověď klientovi. Můžete potvrdit ponechání
                bez odpovědi, nebo ji vrátit ke konzultaci.
              </h5>
              <Button
                style={{ marginRight: "3px" }}
                variant="danger"
                onClick={() => handleFinalDontAnswer()}
              >
                Ponechat bez odpovědi
              </Button>
              <Button onClick={() => handleGoBackToConsultations()}>
                Vrátit k odpovídání
              </Button>
            </Card.Body>
          </Card>
        ) : null}
        {/* if status is rozpracovano, remove last answer as it is editable*/}
        {record.status === getStatus("rozpracovano") ||
        record.status === getStatus("novaodpoved")
          ? answerAccordions.slice(0, -1)
          : answerAccordions}
        {record.status === getStatus("novy") ? (
          <EditableAnswerAccordion
            record={record}
            recordId={recordId}
            refreshParent={loadRecord}
            type={RecordType.trustbox}
          ></EditableAnswerAccordion>
        ) : null}
        {record.status === getStatus("Rozpracováno") ||
        record.status === getStatus("novaodpoved") ? (
          <>
            <br />
            <EditableAnswerAccordion
              record={record}
              recordId={recordId}
              refreshParent={loadRecord}
              answer={record.answers[record.answers.length - 1]}
              type={RecordType.trustbox}
            ></EditableAnswerAccordion>
          </>
        ) : null}
      </Container>
    </>
  );
}

export default TrustboxDetail;
