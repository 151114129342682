import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function SluzbaTopButtons() {
  return (
    <Container>
      <Link to="/sluzba" style={{ marginRight: "3px" }} id={"sluzba"}>
        <Button variant="outline-success" className={"mr-1"}>
          Zobrazit aktuální
        </Button>
      </Link>
      <Link to="/sluzba/email" style={{ marginRight: "3px" }} id={"email"}>
        <Button variant="outline-danger" className={"mr-1"}>
          Zobrazit emaily
        </Button>
      </Link>
      <Link
        to="/sluzba/trustbox"
        style={{ marginRight: "3px" }}
        id={"trustbox"}
      >
        <Button variant="outline-primary" className={"mr-1"}>
          Zobrazit schránku důvěry
        </Button>
      </Link>
      <Link to="/sluzba/alik" style={{ marginRight: "3px" }} id={"alik"}>
        <Button variant="outline-warning" className={"mr-1"}>
          Zobrazit alíky
        </Button>
      </Link>
      <Link to="/sluzba/zapisy/1" className="float-end" id={"zapisy"}>
        <Button variant="outline-info" className={"mr-1"}>
          Zobrazit zápisy
        </Button>
      </Link>
    </Container>
  );
}

export default SluzbaTopButtons;
